import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { Col, Form, Row, Button, InputGroup } from "react-bootstrap";
import { ImageTooltip, ToolTipType } from "..";
import { MIActionType, MonsterInstanceContext, MonsterInstanceContract } from "../../04_Stores";
import { getMonsterTokenBgColor, style_dropdown, style_vertical_center } from "../../constants";
import { IMonsterInstance, ITokenColor, TokenColorArray } from "../../types";

export const RollInitiativeMonster: FunctionComponent<{ monsterId: string }> = ({ monsterId }) => {
  const reloadMonster = (): IMonsterInstance => {
    let monsteri = monsters.data.find((m) => m.id === monsterId) || ({} as IMonsterInstance);
    return monsteri;
  };

  const [monsters, dispatchMonsters] = useContext<MonsterInstanceContract>(MonsterInstanceContext);
  const [monster, setMonster] = useState<IMonsterInstance>(reloadMonster());
  const [tokenColor, setTokenColor] = useState<ITokenColor>(monster.color);
  const [initiative, setInitiative] = useState<number>(monster.rolledInitiative);
  const [initiativeCalculation, setInitiativeCaluclation] = useState<JSX.Element>(<></>);

  useEffect(() => {
    monster.color = tokenColor;
    monster.buildName();
    dispatchMonsters({
      type: MIActionType.UpdateInitialize,
      payload: [],
    });
  }, [tokenColor]);

  useEffect(() => {
    monster.rolledInitiative = initiative;
    dispatchMonsters({
      type: MIActionType.UpdateInitialize,
      payload: [],
    });
  }, [initiative]);

  useEffect(() => {
    var text = (
      <>
        {"rolled initiative"}
        <br />
        {"[1d20] "}
        <strong>{monster.rolledInitiative - monster.getAbilityScore("dex")}</strong>
        {" + [dex] "}
        <strong>{monster.getAbilityScore("dex")}</strong>
      </>
    );

    setInitiativeCaluclation(text);
  }, []);

  const deleteMonster = () => {
    dispatchMonsters({
      type: MIActionType.Remove,
      payload: [monster],
    });
  };

  return (
    <Row className="mb-2 me-1">
      <Col className={style_vertical_center} sm={4}>
        {monster.name}
      </Col>
      <Col sm={1} className={style_vertical_center}>
        <ImageTooltip toolTipType={ToolTipType.HitPoints} styling="me-2"></ImageTooltip>
        {monster.maxHP}
      </Col>
      <Col sm={1} className={style_vertical_center}>
        <ImageTooltip toolTipType={ToolTipType.PassivePerception} styling="me-2"></ImageTooltip>
        {monster.monsterBase.passivePerception}
      </Col>
      <Col sm={2} className="d-flex">
        <InputGroup>
          <InputGroup.Text>
            <ImageTooltip toolTipType={ToolTipType.RolledInitiative} text={initiativeCalculation}></ImageTooltip>
          </InputGroup.Text>
          <Form.Control
            type="number"
            value={initiative}
            placeholder=""
            onChange={(e) => setInitiative(parseInt(e.currentTarget.value))}
          />
        </InputGroup>
      </Col>

      <Col sm={3}>
        <InputGroup>
          <InputGroup.Text style={{ minWidth: 60, fontSize: 13 }}>{"Token"}</InputGroup.Text>
          <Form.Select
            className={style_dropdown}
            value={tokenColor.index}
            onChange={(e) => setTokenColor(TokenColorArray[parseInt(e.currentTarget.value)])}
            style={getMonsterTokenBgColor(tokenColor)}
          >
            {TokenColorArray.map((value) => (
              <option key={value.index} value={value.index} style={getMonsterTokenBgColor(value)}>
                {value.name}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
      </Col>
      <Col sm={1} className="">
        <Button
          variant="outline-secondary"
          onClick={() => {
            deleteMonster();
          }}
        >
          x
        </Button>
      </Col>
    </Row>
  );
};
