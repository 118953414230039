import { Dispatch, FunctionComponent, SetStateAction, useContext, useEffect, useState } from "react";
import { Col, Row, Form, InputGroup, Button } from "react-bootstrap";
import { ImageTooltip, ToolTipType } from "..";
import { MonsterInstanceContext, MonsterInstanceContract } from "../../04_Stores";
import { getMonsterTokenBgColor, style_player_combattracker_col } from "../../constants";

import { IMonsterInstance } from "../../types";

export const MonsterBar: FunctionComponent<{
  monsterId: string;
  setMonsterDetail: Dispatch<SetStateAction<IMonsterInstance | undefined>>;
  renderDetails: Dispatch<SetStateAction<boolean>>;
  renderRollDice: Dispatch<SetStateAction<boolean>>;
}> = ({ monsterId, setMonsterDetail, renderDetails, renderRollDice }) => {
  const reloadMonster = (): IMonsterInstance => {
    let monsteri = monsters.data.find((m) => m.id === monsterId) || ({} as IMonsterInstance);
    return monsteri;
  };
  const [monsters, dispatchMonsters] = useContext<MonsterInstanceContract>(MonsterInstanceContext);
  const [monster, setMonster] = useState<IMonsterInstance>(reloadMonster());
  const [hp, setHp] = useState<number>(monster.currentHP);
  const [damage, setDamage] = useState<number>();
  const [heart, setHeart] = useState<string>("img/roundtracker/heart-green.svg");
  const [armorClass, setArmorClass] = useState<string>("");

  useEffect(() => {
    let acstr = "";
    Object.entries(monster.monsterBase.ac).forEach(([key, value]) => (acstr = acstr + " " + value));
    setArmorClass(acstr);
  }, []);

  const takeDamage = () => {
    if (damage) {
      setHp((hp) => hp - damage);
    }
    setDamage(0);
  };

  const setMonsterDetailView = () => {
    setMonsterDetail(monster);
    renderDetails(true);
  };

  const setMonsterRollDiceView = () => {
    setMonsterDetail(monster);
    renderRollDice(true);
  };

  useEffect(() => {
    monster.currentHP = hp;
    if (hp <= 0) {
      setHeart("img/roundtracker/ghost.svg");
    } else if (hp <= monster.maxHP / 4) {
      setHeart("img/roundtracker/heart-bad.svg");
    } else if (hp <= monster.maxHP / 2) {
      setHeart("img/roundtracker/heart-yellow.svg");
    } else {
      setHeart("img/roundtracker/heart-green.svg");
    }
  }, [hp]);

  return (
    <Row className={style_player_combattracker_col + " mb-2 bg-indigo-1 align-items-stretch"}>
      <Col sm={2} style={getMonsterTokenBgColor(monster.color)} className="pt-1" onClick={setMonsterDetailView}>
        {monster.name}
      </Col>
      <Col sm={1} className={style_player_combattracker_col}>
        <ImageTooltip toolTipType={ToolTipType.Initiative} styling="me-2"></ImageTooltip>
        {monster.rolledInitiative}
      </Col>

      {/* HP */}
      <Col sm={2} className={style_player_combattracker_col}>
        <ImageTooltip imageUrl={heart} toolTipType={ToolTipType.MaxCurrentHp} styling="me-2"></ImageTooltip>
        <InputGroup>
          <InputGroup.Text style={{ minWidth: 60, fontSize: 13 }}>{monster.maxHP + " /"}</InputGroup.Text>
          <Form.Control type="number" value={hp} onChange={(e) => setHp(parseInt(e.currentTarget.value))} />
        </InputGroup>
      </Col>

      {/* armor class */}
      <Col sm={1} className={style_player_combattracker_col}>
        <ImageTooltip toolTipType={ToolTipType.ArmorClass} styling="me-2"></ImageTooltip>
        {armorClass}
      </Col>

      {/* Damage */}
      <Col sm={2} className={style_player_combattracker_col}>
        <InputGroup>
          <Form.Control
            type="number"
            value={damage}
            onKeyUp={(e) => (e.key === "Enter" ? takeDamage() : null)}
            onChange={(e) => setDamage(parseInt(e.currentTarget.value))}
          />
          <Button variant="secondary" onClick={() => takeDamage()}>
            <ImageTooltip toolTipType={ToolTipType.DealDamage}></ImageTooltip>
          </Button>
        </InputGroup>
      </Col>

      <Col sm={1} className={style_player_combattracker_col} onClick={setMonsterRollDiceView}>
        <ImageTooltip toolTipType={ToolTipType.DiceRoll} styling="me-2"></ImageTooltip>
      </Col>
    </Row>
  );
};
