export enum StatusCondition {
  Blinded = "Blinded 👁",
  Charmed = "Charmed",
  Deafened = "Deafened",
  Frightened = "Frightened",
  Grappled = "Grappled",
  Incapacitated = "Incapacitated",
  Invisible = "Invisible",
  Paralyzed = "Paralyzed",
  Petrified = "Petrified",
  Poisoned = "Poisoned",
  Prone = "Prone",
  Restrained = "Restrained",
  Stunned = "Stunned",
  Suffocating = "Suffocating",
  Unconscious = "Unconscious",
}
