import { createContext, Dispatch } from "react";

export enum RTActionType {
  Increase = "Increase",
}

export type RTAction = {
  type: RTActionType;
};

export type RoundTrackerState = {
  counter: number;
};

export const roundTrackerReducer = (state: RoundTrackerState, action: RTAction) => {
  if (action.type === RTActionType.Increase) {
    return { counter: state.counter + 1 };
  }

  return state;
};

export const RoundTrackerContext = createContext<RoundTrackerContract>({} as RoundTrackerContract);

export type RoundTrackerContract = [RoundTrackerState, Dispatch<RTAction>];
