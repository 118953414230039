import { FunctionComponent, useEffect } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { ISong } from "../types";
import { inputPlaceholder, labelStyling, rowStyling, timePlaceholder } from "./songConstants";

export const QuizSong: FunctionComponent<{
  description: string;
  song: ISong;
  setSong: React.Dispatch<React.SetStateAction<ISong>>;
  resetErrors: () => void;
}> = ({ description, song, setSong, resetErrors }) => {
  const updateTime = (value: string) => {
    resetErrors();
    setSong({ song: song.song, start_time: value, category: song.category });
  };

  const updateSong = (value: string) => {
    resetErrors();
    setSong({ song: value, start_time: song.start_time, category: song.category });
  };

  useEffect(() => {
    resetErrors();
  }, [song]);

  return (
    <Row className={rowStyling}>
      <Col sm={10}>
        <InputGroup>
          <InputGroup.Text className={labelStyling} id="chill-input-text">
            {description}
          </InputGroup.Text>
          <Form.Control
            id="chill-input"
            aria-describedby="chill-input-text"
            type="text"
            value={song.song ?? ""}
            placeholder={inputPlaceholder}
            onChange={(e) => updateSong(e.currentTarget.value)}
          />
        </InputGroup>
      </Col>
      <Col sm={2}>
        <Form.Control
          type="text"
          value={song.start_time ?? ""}
          placeholder={timePlaceholder}
          onChange={(e) => updateTime(e.currentTarget.value)}
        />
      </Col>
    </Row>
  );
};
