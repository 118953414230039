import { createContext, Dispatch } from "react";
import { IMonsterInstance } from "../types";

export enum MIActionType {
  ReplaceAll = "REPLACEALL",
  Reset = "RESET",
  UpdateInitialize = "UPDATEINITIALIZE",
  Remove = "REMOVE",
  AddOne = "ADDONE",
}

export type MIAction = {
  type: MIActionType;
  payload: IMonsterInstance[];
};

export type MonsterInstanceState = {
  data: IMonsterInstance[];
};

export const monsterInstanceRollInitiativeReducer = (state: MonsterInstanceState, action: MIAction) => {
  if (action.type === MIActionType.ReplaceAll) {
    return { data: action.payload };
  } else if (action.type === MIActionType.Reset) {
    return { data: [] as IMonsterInstance[] };
  } else if (action.type === MIActionType.UpdateInitialize) {
    return { data: state.data };
  } else if (action.type === MIActionType.Remove) {
    let remainingMonsters = state.data.filter((m) => m.id !== action.payload[0].id) || ({} as IMonsterInstance);
    return { data: remainingMonsters };
  } else if (action.type === MIActionType.AddOne) {
    let newMonster = [...state.data, action.payload[0]];
    return { data: newMonster };
  }

  return state;
};

export const MonsterInstanceContext = createContext<MonsterInstanceContract>({} as MonsterInstanceContract);

//   monsterInstance: initState,
// mutateMonsterInstance: null,

export type MonsterInstanceContract = [MonsterInstanceState, Dispatch<MIAction>];
