import { FunctionComponent, useReducer } from "react";
import { Container, Tab, Row, Nav } from "react-bootstrap";
import {
  MonsterCombatTracker,
  InitiativeTracker,
  CombatRandomizer,
  CombatRoleplayRandomizer,
  ItemRandomizer,
  NpcRandomizer,
} from "../02_tabs";
import {
  MonsterInstanceContext,
  monsterInstanceRollInitiativeReducer,
  PlayerInstanceContext,
  playerInstanceRollInitiativeReducer,
  roundTrackerReducer,
  RoundTrackerContext,
} from "../04_Stores";
import { IMonsterInstance, PlayerInstance } from "../types";

export const CombatTrackerPage: FunctionComponent<{}> = () => {
  //initialize stores
  const [monsterState, dispatchMonsters] = useReducer(monsterInstanceRollInitiativeReducer, {
    data: [] as IMonsterInstance[],
  });
  const [playerState, dispatchPlayers] = useReducer(playerInstanceRollInitiativeReducer, {
    data: [] as PlayerInstance[],
  });
  const [roundTracker, setRoundTracker] = useReducer(roundTrackerReducer, { counter: 0 });

  const baseButtonStyling = "text-white";
  const toolsbuttonstyling = baseButtonStyling + " bg-blue-5";
  const navItemSpacing = "px-3 flex-fill text-center";
  const randomizerbuttonstyling = baseButtonStyling + " bg-blue-5";

  return (
    <Container fluid>
      <Tab.Container id="custom-tab-example" defaultActiveKey="initiative_tracker">
        <Row className="bg-gray-9">
          <Nav variant="pills" className="container-fluid d-flex flex-nowrap py-3">
            <Nav.Item className={navItemSpacing}>
              <Nav.Link className={toolsbuttonstyling} eventKey="initiative_tracker">
                Initiative Tracker
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={navItemSpacing}>
              <Nav.Link className={randomizerbuttonstyling} eventKey="combat_randomizer">
                Combat Randomizer
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={navItemSpacing}>
              <Nav.Link className={randomizerbuttonstyling} eventKey="item_randomizer">
                Loot Randomizer
              </Nav.Link>
            </Nav.Item>
            {/* 
            <Nav.Item className={navItemSpacing}>
              <Nav.Link className={toolsbuttonstyling} eventKey="player_overview">
                Player Overview
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={navItemSpacing}>
              <Nav.Link className={toolsbuttonstyling} eventKey="player_combat_tracker">
                Player Combat Tracker
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={navItemSpacing}>
              <Nav.Link className={toolsbuttonstyling} eventKey="monster_combat_tracker">
                Monster Combat Tracker
              </Nav.Link>
            </Nav.Item>
            */}
          </Nav>
        </Row>

        <RoundTrackerContext.Provider value={[roundTracker, setRoundTracker]}>
          <MonsterInstanceContext.Provider value={[monsterState, dispatchMonsters]}>
            <PlayerInstanceContext.Provider value={[playerState, dispatchPlayers]}>
              <Row>
                <Tab.Content className="container-fluid">
                  <Tab.Pane eventKey="player_overview">{/*<PlayerOverview></PlayerOverview>*/}</Tab.Pane>

                  <Tab.Pane eventKey="player_combat_tracker">
                    {/*<PlayerCombatTracker></PlayerCombatTracker>*/}
                  </Tab.Pane>
                  <Tab.Pane eventKey="monster_combat_tracker">
                    <MonsterCombatTracker></MonsterCombatTracker>
                  </Tab.Pane>

                  <Tab.Pane eventKey="initiative_tracker">
                    <InitiativeTracker></InitiativeTracker>
                  </Tab.Pane>
                </Tab.Content>
                <Tab.Content className="container-fluid">
                  <Tab.Pane eventKey="combat_randomizer">
                    <CombatRandomizer></CombatRandomizer>
                    <CombatRoleplayRandomizer></CombatRoleplayRandomizer>
                  </Tab.Pane>
                  <Tab.Pane eventKey="item_randomizer">
                    <ItemRandomizer></ItemRandomizer>
                  </Tab.Pane>
                  <Tab.Pane eventKey="npc_randomizer">
                    <NpcRandomizer></NpcRandomizer>
                  </Tab.Pane>
                </Tab.Content>
              </Row>
            </PlayerInstanceContext.Provider>
          </MonsterInstanceContext.Provider>
        </RoundTrackerContext.Provider>
      </Tab.Container>
    </Container>
  );
};
