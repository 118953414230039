import { ICampaign, IEncounter, IMonsterBase } from "../../types";

export const readCampaignsFromJson = (): Promise<ICampaign[]> => {
  return readJsonByType<ICampaign[]>("data/players.json");
};

export const readMonstersMMfromJson = (): Promise<IMonsterBase[]> => {
  return readJsonByType<IMonsterBase[]>("data/monsters_mm.json");
};

export const readMonstersIDROTFfromJson = (): Promise<IMonsterBase[]> => {
  return readJsonByType<IMonsterBase[]>("data/monsters_idrotf.json");
};

export const readMonstersVGMfromJson = (): Promise<IMonsterBase[]> => {
  return readJsonByType<IMonsterBase[]>("data/monsters_vgm.json");
};

export const readEncountersFromJson = (): Promise<IEncounter[]> => {
  return readJsonByType<IEncounter[]>("data/encounters.json");
};

//Randomizers
export const readRandomCritFromJson = (): Promise<ITableEntry[]> => {
  return readJsonByType<ITableEntry[]>("data/randomizer/combat/combat_critical_hit.json");
};

export const readRandomMissFromJson = (): Promise<ITableEntry[]> => {
  return readJsonByType<ITableEntry[]>("data/randomizer/combat/combat_critical_miss.json");
};

export const readRandomSpellcastingMisshapFromJson = (): Promise<ITableEntry[]> => {
  return readJsonByType<string[]>("data/randomizer/combat/combat_spellcasting_mishap.json").then((result) =>
    result.map((value) => {
      return { text: value };
    })
  );
};

export const readRandomHitAreaHumanoidFromJson = (): Promise<ITableEntry[]> => {
  return readJsonByType<ITableEntry[]>("data/randomizer/combat/combat_hit_area_humanoid.json");
};

export const readRandomHitAreaMonsterFromJson = (): Promise<ITableEntry[]> => {
  return readJsonByType<ITableEntry[]>("data/randomizer/combat/combat_hit_area_monster.json");
};

export const readRandomInjuryFromJson = (): Promise<ITableEntry[]> => {
  return readJsonByType<string[]>("data/randomizer/combat/combat_injuries.json").then((result) =>
    result.map((value) => {
      return { text: value };
    })
  );
};

export const readRandomJunkItemFromJson = (): Promise<ITableEntry[]> => {
  return readJsonByType<string[]>("data/randomizer/items/items_junk.json").then((result) =>
    result.map((value) => {
      return { text: value };
    })
  );
};

export const readRandomMundaneItemFromJson = (): Promise<ITableEntry[]> => {
  return readJsonByType<IItemEntry[]>("data/randomizer/items/items_all_basic.json").then((result) =>
    result.map((value) => {
      return { text: value.name };
    })
  );
};

export const readRandomGemstoneFromJson = (): Promise<IGemstone[]> => {
  return readJsonByType<IGemstone[]>("data/randomizer/items/items_gemstones.json");
};

export const readRandomArtObjectFromJson = (): Promise<IGemstone[]> => {
  return readJsonByType<IGemstone[]>("data/randomizer/items/items_art_objects.json");
};

export const readRandomMagicItemFromJson = (): Promise<IItem[]> => {
  return readJsonByType<IItem[]>("data/randomizer/items/items_all.json");
};

const readJsonByType = <T>(document: string): Promise<T> => {
  return fetch(document)
    .then((r) => r.json())
    .then((d) => {
      let castedData: T = d;
      return castedData;
    });
};

export interface ITableEntry {
  text: string;
}

export interface IItemEntry {
  name: string;
  rarity: string;
}

export interface IGemstone {
  value: number;
  currency: string;
  name: string;
}

export interface IItem {
  name: string;
  rarity: string;
}
