import { FunctionComponent, useEffect, useState } from "react";
import { MagicItemRarities, RandomizerType, readRandomMagicItemFromJson } from "../../api";
import { Row, Col, Button, Form } from "react-bootstrap";
import { IItem } from "../../api";
import { style_column_button, style_column_content, style_row } from "../../constants";
import { style_button, style_column_dropdown, style_dropdown } from "../../constants/styling";

export const MagicItemRandomizer: FunctionComponent<{
  randomizerType: RandomizerType;
  randomizerName: string;
  buttonVariant: string;
}> = ({ randomizerName, randomizerType, buttonVariant }) => {
  const [randomItem, setRandomItem] = useState<IItem>({ name: "", rarity: "" });
  const [itemTable, setItemTable] = useState<IItem[]>();
  const [rarity, setRarity] = useState<string>(MagicItemRarities[0]);
  const [renderResult, setRenderResult] = useState<string>("d-none");

  const getRandomRoll = () => {
    var itemsByValue = itemTable?.filter((item) => item.rarity === rarity);
    if (itemsByValue) {
      var randomEntry = itemsByValue[Math.floor(Math.random() * itemsByValue.length)];
      setRandomItem(randomEntry);
      setRenderResult("");
    }
  };

  useEffect(() => {
    readRandomMagicItemFromJson().then((result) => setItemTable(result));
  }, []);

  return (
    <Row className={style_row}>
      <Col sm={2} className={style_column_button}>
        <Button className={style_button} variant={buttonVariant} onClick={() => getRandomRoll()}>
          {randomizerName}
        </Button>
      </Col>

      <Col sm={2} className={style_column_dropdown}>
        <Form.Select className={style_dropdown} value={rarity} onChange={(e) => setRarity(e.currentTarget.value)}>
          {MagicItemRarities.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </Form.Select>
      </Col>

      <Col sm={8} className={style_column_content}>
        <div className={renderResult}>
          {randomItem.name} (rarity: {randomItem.rarity})
        </div>
      </Col>
    </Row>
  );
};
