import { FunctionComponent, useState } from "react";
import { Container, Row, Form, Col, Button, Alert, Accordion } from "react-bootstrap";
import { QuizSong } from "../../01_quiz/song";
import { style_vertical_center } from "../../constants";
import { IQuizForm, ISong, QuizNames } from "../../types";
import { AESencrypt, db } from "../../utils";
import { addDoc, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

export const MusicQuizPage: FunctionComponent<{}> = () => {
  const alignCenter = "d-flex align-items-center justify-content-center";
  const [chill, setChill] = useState<ISong>({ song: null, start_time: null, category: "chill" });
  const [pickup, setPickup] = useState<ISong>({ song: null, start_time: null, category: "pickup" });
  const [genre, setGenre] = useState<ISong>({ song: null, start_time: null, category: "genre" });
  const [secret, setSecret] = useState<ISong>({ song: null, start_time: null, category: "secret" });
  const [hate, setHate] = useState<ISong>({ song: null, start_time: null, category: "hate" });
  const [memories, setMemories] = useState<ISong>({ song: null, start_time: null, category: "memories" });
  const [party, setParty] = useState<ISong>({ song: null, start_time: null, category: "party" });
  const [bonus, setBonus] = useState<ISong>({ song: null, start_time: null, category: "bonus" });
  const songs = [chill, pickup, genre, secret, hate, memories, party, bonus];

  //const [name, setName] = useState<string>("");
  const SELECT_PLACEHOLDER = { label: "choose your name", value: "UNDEFINED" };
  const selectNames: readonly { label: string; value: string }[] = [
    SELECT_PLACEHOLDER,
    ...QuizNames.map((value) => {
      return { label: value, value: value };
    }),
  ];
  const [name, setName] = useState<{ label: string; value: string }>(SELECT_PLACEHOLDER);

  const [songError, setSongError] = useState<boolean>(false);
  const [emptyError, setEmptyError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);

  const navigate = useNavigate();

  const writeQuziEntryAsync = async (entry: IQuizForm) => {
    try {
      const docRef = await addDoc(collection(db, "quizentries"), entry);
      console.log(docRef.id);
      navigate("/musicquiz/submit/" + docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const resetErrors = () => {
    setEmptyError(false);
    setSongError(false);
  };

  const submit = () => {
    resetErrors();
    let counter = 0;
    for (const song of songs) {
      if (song.song === null || song.song.length === 0) {
        counter++;
      }
    }
    if (counter === songs.length) {
      setEmptyError(true);
      return;
    }
    if (counter !== 0) {
      setSongError(true);
      return;
    }
    if (name.label === SELECT_PLACEHOLDER.label || name.value === SELECT_PLACEHOLDER.value) {
      setNameError(true);
      return;
    }
    if (process.env.REACT_APP_CRYPTOJS_SECRET_KEY === undefined) {
      return;
    }

    const encryptedName = AESencrypt(name.value, process.env.REACT_APP_CRYPTOJS_SECRET_KEY);

    const entryRequest = {
      owner: encryptedName,
      song_chill: chill,
      song_genre: genre,
      song_hate: hate,
      song_memories: memories,
      song_party: party,
      song_pickup: pickup,
      song_bonus: bonus,
      song_secret: secret,
    } as IQuizForm;

    writeQuziEntryAsync(entryRequest);
  };

  return (
    <Container fluid className="p-5">
      <h1 className={alignCenter}>🤘 Goldfinger Music Quiz Form 🤘</h1>
      <p className={alignCenter}>Welcome to the one and only goldfinger music quiz</p>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Click here to view the rules</Accordion.Header>
          <Accordion.Body>
            <div className="w-50">
              <h5>submit songs in the correct format</h5>
              <p>
                For each category you can provide a song of your choice. <br />
                During the quizz we will listen to the first minute of each song. <br />
                There are two ways you can submit a song:
              </p>
              <ol type="a">
                <li>
                  Enter <b>Artist + Songname</b> <br />
                  <i>Example: AC/DC Highway to Hell</i>
                </li>
                <li>
                  Provide a <b>Youtube Link to the Song</b> <br />
                  <i>
                    Example:{" "}
                    <a href="https://www.youtube.com/watch?v=l482T0yNkeo">
                      https://www.youtube.com/watch?v=l482T0yNkeo
                    </a>
                  </i>
                </li>
              </ol>
              <h5>(optional) choose a start time for your song</h5>
              <p>
                Since we only listen to 1 minute of each song, there is a good chance your favourite part of it won't
                get played. <br />
                If you'd like the song to start playing at a spesific time during the quizz{" "}
                <i>(e.g start playing at 2:15)</i> you can enter your desired start time in <i>[mm:ss]</i> next to your
                song.
                <br />
                If you don't enter a start time, the first minute of the song will be played.
              </p>
              <h5>Add your RL Name</h5>
              <p>
                In order to evaluate the quiz-results, please provide your name. It will be encrypted and only the
                cipher is stored on the database. So not even the game master can cheat. At the end of the quiz the
                results can be evaluated by decrypting the cipher again.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div className="mb-5" />
      {songError && (
        <Alert key="noSongs" variant="danger">
          <b>You need to provide a song for each category</b>{" "}
          <i>
            (I am too lazy to highlight every textfield you left empty. Figure it out yourself, you're a smart cookie
            🍪)
          </i>
        </Alert>
      )}

      {emptyError && (
        <Alert key="emptyError" variant="danger">
          <b>Ah, very funny. Trying to submit an empty form, how creative.</b>
        </Alert>
      )}

      {nameError && (
        <Alert key="nameError" variant="danger">
          <b>Please provide your name</b> (I won't sell your data to Facebook I promise)
        </Alert>
      )}

      <Row className={"mb-2"}>
        <Col sm={10} className="d-flex">
          <div className={"w-25 " + alignCenter}>
            {" "}
            <b>Category</b>
          </div>
          <div className={"w-75 " + alignCenter}>
            <b>choose your song</b>
          </div>
        </Col>
        <Col sm={2} className={alignCenter}>
          <b>(optional) start time</b>
        </Col>
      </Row>
      <QuizSong
        description="🍹 your favourite song to chill 🍹"
        song={chill}
        setSong={setChill}
        resetErrors={resetErrors}
      ></QuizSong>
      <QuizSong
        description="💪 a song that always lifts your spirits 💪"
        song={pickup}
        setSong={setPickup}
        resetErrors={resetErrors}
      ></QuizSong>
      <QuizSong
        description="🎸 your favourite music genre 🎸"
        song={genre}
        setSong={setGenre}
        resetErrors={resetErrors}
      ></QuizSong>
      <QuizSong
        description="🙊 You secretly like this kind of music 🙊"
        song={secret}
        setSong={setSecret}
        resetErrors={resetErrors}
      ></QuizSong>
      <QuizSong description="🗑️ A song you hate 🗑️" song={hate} setSong={setHate} resetErrors={resetErrors}></QuizSong>
      <QuizSong
        description="🏞 This song brings back memories 🏞"
        song={memories}
        setSong={setMemories}
        resetErrors={resetErrors}
      ></QuizSong>
      <QuizSong
        description="🥳 best party / beer evening song 🥳"
        song={party}
        setSong={setParty}
        resetErrors={resetErrors}
      ></QuizSong>
      <QuizSong
        description="⭐️ Bonus song - choose whatever you like ⭐️"
        song={bonus}
        setSong={setBonus}
        resetErrors={resetErrors}
      ></QuizSong>
      <Row className={"mb-4 mt-4"}>
        <Col sm={4} />
        <Col sm={1} className={style_vertical_center}>
          <b>Your Name</b>
        </Col>

        <Col sm={3}>
          <Select value={name} onChange={(e) => setName(e === null ? SELECT_PLACEHOLDER : e)} options={selectNames} />
        </Col>
      </Row>
      <div className="d-flex align-items-center justify-content-center">
        <Button className="w-25" variant="success" onClick={() => submit()}>
          Submit Form
        </Button>
      </div>
      <div className="mb-5" />
    </Container>
  );
};

/* <Col sm={3}>
          <Form.Control type="text" placeholder="Pablo" value={name} onChange={(e) => setName(e.currentTarget.value)} />
        </Col>*/
