import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { addNewMonster } from "..";
import { MonsterInstanceContext, MonsterInstanceContract } from "../../04_Stores";
import { readMonstersIDROTFfromJson, readMonstersMMfromJson, readMonstersVGMfromJson } from "../../api";
import { getMonsterTokenBgColor, style_dropdown } from "../../constants";
import { IMonsterBase, ITokenColor, MonsterInstance, TokenColorArray } from "../../types";

const BASEMONSTER_PLACEHOLDER = { label: "- select a monster -", value: "UNDEFINED_MONSTER" };

export const AddMonsterDialog: FunctionComponent<{
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  addMonsterCallback: (newMonster: MonsterInstance) => void;
}> = ({ show, setShow, addMonsterCallback }) => {
  //base data
  const [baseMonsters, setBaseMonsters] = useState<IMonsterBase[]>([]);
  const [monsters, dispatchMonsters] = useContext<MonsterInstanceContract>(MonsterInstanceContext);

  //current choice
  const [baseMonsterName, setBaseMonsterName] = useState<{ label: string; value: string }>(BASEMONSTER_PLACEHOLDER);
  const [tokenColor, setTokenColor] = useState<ITokenColor>(TokenColorArray[0]);
  const [randHp, setRandHp] = useState<boolean>(false);

  //when initializing page, load players and encounters
  useEffect(() => {
    readMonstersMMfromJson().then((result) => setBaseMonsters((oldarr) => [...oldarr, ...result]));
    readMonstersIDROTFfromJson().then((result) => setBaseMonsters((oldarr) => [...oldarr, ...result]));
    readMonstersVGMfromJson().then((result) => setBaseMonsters((oldarr) => [...oldarr, ...result]));
  }, []);

  const triggerAddMonster = () => {
    let monsterInstance = addNewMonster(baseMonsters, randHp, baseMonsterName, tokenColor, monsters, dispatchMonsters);
    addMonsterCallback(monsterInstance);

    setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add Monster</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* choose monster */}
        <Row>
          <Col sm={12}>
            <Select
              value={baseMonsterName}
              onChange={(e) => setBaseMonsterName(e === null ? BASEMONSTER_PLACEHOLDER : e)}
              options={baseMonsters.map((value) => {
                return { label: value.name, value: value.name };
              })}
            />
          </Col>
        </Row>

        {/* choose token color */}
        <Row className="mt-3">
          <Col sm={12}>
            <Form.Select
              className={style_dropdown}
              value={tokenColor.index}
              onChange={(e) => setTokenColor(TokenColorArray[parseInt(e.currentTarget.value)])}
              style={getMonsterTokenBgColor(tokenColor)}
            >
              {TokenColorArray.map((value) => (
                <option key={value.index} value={value.index} style={getMonsterTokenBgColor(value)}>
                  {value.name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        {/* random hp */}
        <Row className="mt-3">
          <Col>
            <Form.Check
              type="checkbox"
              label="Random HP"
              checked={randHp}
              onChange={() => {
                setRandHp((r) => r!);
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            triggerAddMonster();
          }}
        >
          Add Monster
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
