import { FunctionComponent, useContext, useState } from "react";
import { Col, Row, Form, InputGroup } from "react-bootstrap";
import { ImageTooltip, ToolTipType } from "..";
import { PlayerInstanceContext, PlayerInstanceContract } from "../../04_Stores";
import { style_player_combattracker_col } from "../../constants";

import { PlayerInstance } from "../../types";

export const PlayerBar: FunctionComponent<{ playerId: string }> = ({ playerId }) => {
  const reloadPlayer = (): PlayerInstance => {
    let monsteri = players.data.find((m) => m.id === playerId) || ({} as PlayerInstance);
    return monsteri;
  };
  const [players, dispatchPlayers] = useContext<PlayerInstanceContract>(PlayerInstanceContext);
  const [player, setMonster] = useState<PlayerInstance>(reloadPlayer());
  const [hp, setHp] = useState<number>(player.currentHp);

  return (
    <Row className="mb-2 bg-cyan-1">
      <Col sm={2} className={style_player_combattracker_col}>
        {player.playerBase.characterName + " (" + player.playerBase.playerName + ")"}
      </Col>
      <Col sm={1} className={style_player_combattracker_col}>
        <ImageTooltip toolTipType={ToolTipType.Initiative} styling="me-2"></ImageTooltip>
        {player.rolledInitiative}
      </Col>
      <Col sm={2} className={style_player_combattracker_col}>
        <ImageTooltip toolTipType={ToolTipType.MaxCurrentHp} styling="me-2"></ImageTooltip>
        <InputGroup>
          <InputGroup.Text style={{ minWidth: 60, fontSize: 13 }}>{player.playerBase.maxHp + " /"}</InputGroup.Text>
          <Form.Control
            type="number"
            value={hp}
            placeholder=""
            onChange={(e) => setHp(parseInt(e.currentTarget.value))}
          />
        </InputGroup>
      </Col>
      <Col sm={1} className={style_player_combattracker_col}>
        <ImageTooltip toolTipType={ToolTipType.ArmorClass} styling="me-2" /> {player.playerBase.armorClass}
      </Col>
      <Col sm={1} className={style_player_combattracker_col}>
        <ImageTooltip toolTipType={ToolTipType.SpellSaveDc} styling="me-2" /> {player.playerBase.spellSaveDC}
      </Col>
    </Row>
  );
};
