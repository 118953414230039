import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { MonsterBar, MonsterDetail, RollDiceDialog, PlayerBar } from "../../03_components";
import { AddMonsterDialog } from "../../03_components/roundTracker/AddMonsterDialog";
import {
  MonsterInstanceContext,
  MonsterInstanceContract,
  PlayerInstanceContext,
  PlayerInstanceContract,
  RoundTrackerContext,
  RoundTrackerContract,
  RTActionType,
} from "../../04_Stores";
import { style_player_combattracker_row_base } from "../../constants";
import { IMonsterInstance, MonsterInstance } from "../../types";

export const RoundTracker: FunctionComponent<{}> = () => {
  //base states
  const [monsters, dispatchMonsters] = useContext<MonsterInstanceContract>(MonsterInstanceContext);
  const [players, dispatchPlayers] = useContext<PlayerInstanceContract>(PlayerInstanceContext);

  //participant tracking
  const [roundTracker, setRoundTracker] = useContext<RoundTrackerContract>(RoundTrackerContext);
  const [participants, setParticipants] = useState<IRoundParticipant[]>([]);
  const [currentRound, setCurrentRound] = useState<IRoundParticipant[]>([]);
  const [nextRound, setNextRound] = useState<IRoundParticipant[]>([]);
  const [deadMonsters, setDeadMonsters] = useState<IRoundParticipant[]>([] as IRoundParticipant[]);

  //details
  const [monsterDetail, setMonsterDetail] = useState<IMonsterInstance>();
  const [showMonsterDetails, setShowMonsterDetails] = useState<boolean>(false);

  //roll dice for monsters
  const [showMonsterRollDice, setShowMonsterRollDice] = useState<boolean>(false);

  //add new monster to combat
  const [showAddMonster, setShowAddMonster] = useState<boolean>(false);

  //only run once - setup order array
  useEffect(() => {
    let participantArr: IRoundParticipant[] = [];
    monsters.data.forEach((m) => {
      let monsterBar = {
        id: m.id,
        rolledInitiative: m.rolledInitiative,
        isPlayer: false,
        render: (
          <MonsterBar
            monsterId={m.id}
            key={m.id}
            setMonsterDetail={setMonsterDetail}
            renderDetails={setShowMonsterDetails}
            renderRollDice={setShowMonsterRollDice}
          ></MonsterBar>
        ),
      };
      participantArr.push(monsterBar);
    });

    players.data.forEach((p) => {
      let playerBar = {
        id: p.id,
        rolledInitiative: p.rolledInitiative,
        isPlayer: true,
        render: <PlayerBar playerId={p.id} key={p.id}></PlayerBar>,
      };
      participantArr.push(playerBar);
    });

    let initiativeOrder = participantArr.sort(compare);
    setParticipants([...initiativeOrder]);
    setCurrentRound([...initiativeOrder]);
    setRoundTracker({ type: RTActionType.Increase });
  }, []);

  const nextTurn = () => {
    shiftParticipants();

    removeDeadMonsters(monsters.data, currentRound, nextRound, participants, setDeadMonsters);

    prepareIfNewTurn();

    showMonsterDetail();

    setNextRound((a) => [...a]);
    setCurrentRound((a) => [...a]);
  };

  const shiftParticipants = () => {
    let participant = currentRound.shift();

    if (participant) {
      nextRound.push(participant);
    }
  };

  const prepareIfNewTurn = () => {
    if (currentRound.length === 0) {
      while (nextRound.length > 0) {
        let participant = nextRound.shift();
        currentRound.push(participant!);
      }

      setRoundTracker({ type: RTActionType.Increase });
    }
  };

  const showMonsterDetail = () => {
    let participant = currentRound[0];

    if (!participant.isPlayer) {
      setMonsterDetail(monsters.data.find((m) => m.id === participant.id)!);
    }
  };

  const addMonsterToOngoingCombat = (newMonster: MonsterInstance) => {
    let monsterBar: IRoundParticipant = {
      id: newMonster.id,
      rolledInitiative: newMonster.rolledInitiative,
      isPlayer: false,
      render: (
        <MonsterBar
          monsterId={newMonster.id}
          key={newMonster.id}
          setMonsterDetail={setMonsterDetail}
          renderDetails={setShowMonsterDetails}
          renderRollDice={setShowMonsterRollDice}
        ></MonsterBar>
      ),
    };

    let participantIndex = participants.findIndex((p) => p.rolledInitiative < newMonster.rolledInitiative);
    if (participantIndex != -1) {
      participants.splice(participantIndex, 0, monsterBar);
    } else {
      participants.push(monsterBar);
    }
    let slowerParticipant = participants[participantIndex + 1];
    let currArray = currentRound.includes(slowerParticipant) ? currentRound : nextRound;
    let indexCurr = currArray.indexOf(slowerParticipant);
    currArray.splice(indexCurr, 0, monsterBar);

    setNextRound((a) => [...a]);
    setCurrentRound((a) => [...a]);
  };

  return (
    <Container className="pt-4 d-flex flex-column" fluid>
      {/* 1.Row - general tools */}
      <Row className="mb-3">
        <Col sm={10}>
          <Button
            variant="sec-green"
            className="me-1"
            onClick={() => {
              nextTurn();
            }}
          >
            next participant
          </Button>
        </Col>
        <Col sm={2} className="d-flex justify-content-end">
          <Button variant="primary" onClick={() => setShowAddMonster(true)}>
            add monster
          </Button>
        </Col>
      </Row>

      {/* 2.Row - Players and Monsters */}
      <Row>
        {/* turn order */}
        <Col className="d-flex flex-column" sm={12}>
          <Row>
            <Col sm={4} className={style_player_combattracker_row_base + " bg-gray-2"}>
              <span className="fw-light fst-italic">Participants</span>
            </Col>
            {/* current round */}
            <Col className={style_player_combattracker_row_base + " bg-gray-2 text-center"} sm={4}>
              <span className="fw-bold">Round {roundTracker.counter}</span>
            </Col>
            <Col sm={4} className={style_player_combattracker_row_base + " bg-gray-2"}></Col>
          </Row>
          {currentRound.map((p) => p.render)}

          {/* next round */}
          <Row>
            <Col className={style_player_combattracker_row_base + " text-center"} sm={12}>
              <span className="fw-bold">Round {roundTracker.counter + 1}</span>
            </Col>
          </Row>
          {nextRound.map((p) => p.render)}
        </Col>

        {/* creature details */}
        {/* 
        <Col className="d-flex flex-column" sm={6}>
          <MonsterDetail monster={monsterDetail}></MonsterDetail>
        </Col>
        */}
      </Row>

      <Row>
        <Col sm={6}>
          {/* Dead Monsters */}
          <Row>
            <Col className={style_player_combattracker_row_base + " bg-gray-2 text-center mt-5"} sm={12}>
              Dead Monsters
            </Col>
          </Row>
          {deadMonsters.map((p) => p.render)}
        </Col>
        <Col sm={6}></Col>
      </Row>

      <AddMonsterDialog
        show={showAddMonster}
        setShow={setShowAddMonster}
        addMonsterCallback={addMonsterToOngoingCombat}
      ></AddMonsterDialog>

      <MonsterDetail monster={monsterDetail} show={showMonsterDetails} setShow={setShowMonsterDetails}></MonsterDetail>
      <RollDiceDialog show={showMonsterRollDice} setShow={setShowMonsterRollDice}></RollDiceDialog>
    </Container>
  );
};

interface IRoundParticipant {
  id: string;
  rolledInitiative: number;
  isPlayer: boolean;

  render: JSX.Element;
}

const compare = (a: IRoundParticipant, b: IRoundParticipant): number => {
  if (a.rolledInitiative > b.rolledInitiative) {
    return -1;
  }
  if (a.rolledInitiative < b.rolledInitiative) {
    return 1;
  }
  return 0;
};

const removeDeadMonsters = (
  monsters: IMonsterInstance[],
  currentRound: IRoundParticipant[],
  nextRound: IRoundParticipant[],
  participants: IRoundParticipant[],
  setDeadMonsters: React.Dispatch<React.SetStateAction<IRoundParticipant[]>>
) => {
  //add dead monsters to graveyard
  let deadMonsterIds = monsters.filter((m) => m.currentHP <= 0).map((m) => m.id);
  if (deadMonsterIds.length > 0) {
    let deadMonsterBars = [
      ...currentRound.filter((m) => deadMonsterIds.includes(m.id)),
      ...nextRound.filter((m) => deadMonsterIds.includes(m.id)),
    ];
    setDeadMonsters((a) => [...a, ...deadMonsterBars]);

    //remove dead monsters from current round
    let aliveCurrent = currentRound.filter((m) => !deadMonsterIds.includes(m.id));
    while (currentRound.length > 0) {
      currentRound.pop();
    }
    aliveCurrent.forEach((a) => currentRound.push(a));

    //remove dead monsters from next round
    let aliveNext = nextRound.filter((m) => !deadMonsterIds.includes(m.id));
    while (nextRound.length > 0) {
      nextRound.pop();
    }
    aliveNext.forEach((a) => nextRound.push(a));

    //remove dead monsters from participants
    let aliveParticipants = participants.filter((m) => !deadMonsterIds.includes(m.id));
    while (participants.length > 0) {
      participants.pop();
    }
    aliveParticipants.forEach((a) => participants.push(a));
  }
};
