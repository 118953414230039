import { FunctionComponent, useEffect, useState } from "react";

export const ColorSwatch: FunctionComponent<{ hexcolorstate: string; colornamestate: string }> = ({
  hexcolorstate,
  colornamestate,
}) => {
  const [colorCode, setColorCode] = useState<string>("#FFFFFF");

  const swatchStyle = {
    color: colorCode,
  };

  useEffect(() => {
    setColorCode("#" + hexcolorstate);
  }, [hexcolorstate]);

  return (
    <div>
      <span style={swatchStyle}>██</span>
      <span> {colornamestate}</span>
    </div>
  );
};
