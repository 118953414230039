import axios from "axios";

export const BaseApiUrl = "https://kitsuneoncloudsapi.herokuapp.com/dnd/randomizer/v1/";
export const BaseLocalApiUrl = "https://localhost:44328/dnd/randomizer/v1/";

export const callApi = <T>(apiFunction: string): Promise<T> => {
  return axios
    .get(BaseApiUrl + apiFunction, {
      headers: { "Access-Control-Allow-Origin": "*" },
    })
    .then((response) => response.data);
};
