import { FunctionComponent, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ISelectOption, QuizNames } from "../types";
import { rowStyling } from "./songConstants";
import Select from "react-select";

export const SongAnswer: FunctionComponent<{
  songname: string;
  owner: string;
  setAnswers: (name: string, actualOwner: string) => void;
}> = ({ songname, owner, setAnswers }) => {
  const SELECT_PLACEHOLDER = { label: "-choose your answer-", value: "UNDEFINED" };

  const [name, setName] = useState<ISelectOption>(SELECT_PLACEHOLDER);
  const selectNames: readonly ISelectOption[] = [
    SELECT_PLACEHOLDER,
    ...QuizNames.map((value) => {
      return { label: value, value: value };
    }),
  ];

  const updateAnswers = (option: ISelectOption) => {
    setName(option);
    setAnswers(option.value, owner);
  };

  return (
    <Row className={rowStyling}>
      <Col sm={5} className="d-flex justify-content-center align-items-center">
        {songname}
      </Col>
      <Col sm={7}>
        <Select
          value={name}
          onChange={(e) => updateAnswers(e === null ? SELECT_PLACEHOLDER : e)}
          options={selectNames}
        />
      </Col>
    </Row>
  );
};
