import { createContext, Dispatch } from "react";
import { PlayerInstance } from "../types";

export enum PIActionType {
  ReplaceAll = "REPLACEALL",
  Reset = "RESET",
  UpdateInitialize = "UPDATEINITIALIZE",
  Remove = "REMOVE",
  AddOne = "ADDONE",
}

export type PIAction = {
  type: PIActionType;
  payload: PlayerInstance[];
};

export type playerInstanceState = {
  data: PlayerInstance[];
};

export const playerInstanceRollInitiativeReducer = (state: playerInstanceState, action: PIAction) => {
  if (action.type === PIActionType.ReplaceAll) {
    return { data: action.payload };
  } else if (action.type === PIActionType.Reset) {
    return { data: [] as PlayerInstance[] };
  } else if (action.type === PIActionType.UpdateInitialize) {
    return { data: state.data };
  } else if (action.type === PIActionType.Remove) {
    let remainingMonsters = state.data.filter((m) => m.id !== action.payload[0].id) || ({} as PlayerInstance);
    return { data: remainingMonsters };
  } else if (action.type === PIActionType.AddOne) {
    let newPlayer = [...state.data, action.payload[0]];
    return { data: newPlayer };
  }
  return state;
};

export const PlayerInstanceContext = createContext<PlayerInstanceContract>({} as PlayerInstanceContract);

//   monsterInstance: initState,
// mutateMonsterInstance: null,

export type PlayerInstanceContract = [playerInstanceState, Dispatch<PIAction>];
