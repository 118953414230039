import { FunctionComponent, useEffect, useState } from "react";
import { IGemstone, RandomizerType, readRandomArtObjectFromJson, readRandomGemstoneFromJson } from "../../api";
import { Row, Col, Button, Form } from "react-bootstrap";
import { style_column_button, style_column_content, style_row } from "../../constants";
import { style_button, style_column_dropdown, style_dropdown } from "../../constants/styling";

export const ItemsByValueRandomizer: FunctionComponent<{
  randomizerType: RandomizerType;
  randomizerName: string;
  buttonVariant: string;
  itemValues: number[];
}> = ({ randomizerType, randomizerName, buttonVariant, itemValues }) => {
  const [randomItem, setRandomItem] = useState<IGemstone>({
    name: "",
    value: 0,
    currency: "",
  });
  const [itemValue, setItemValue] = useState<number>(itemValues[0]);
  const [itemTable, setItemTable] = useState<IGemstone[]>();
  const [renderResult, setRenderResult] = useState<string>("d-none");

  useEffect(() => {
    switch (randomizerType) {
      case RandomizerType.Item_Gemstone: {
        readRandomGemstoneFromJson().then((result) => setItemTable(result));
        break;
      }
      case RandomizerType.Item_Art: {
        readRandomArtObjectFromJson().then((result) => setItemTable(result));
        break;
      }
    }
  }, []);

  const getRandomRoll = () => {
    var gemstonesByValue = itemTable?.filter((item) => item.value === itemValue);
    if (gemstonesByValue) {
      var randomEntry = gemstonesByValue[Math.floor(Math.random() * gemstonesByValue.length)];
      setRandomItem(randomEntry);
      setRenderResult("");
    }
  };

  return (
    <Row className={style_row}>
      <Col sm={2} className={style_column_button}>
        <Button className={style_button} variant={buttonVariant} onClick={() => getRandomRoll()}>
          {randomizerName}
        </Button>
      </Col>

      <Col sm={2} className={style_column_dropdown}>
        <Form.Select
          className={style_dropdown}
          value={itemValue}
          onChange={(e) => setItemValue(parseInt(e.currentTarget.value))}
        >
          {itemValues.map((value) => (
            <option key={value} value={value}>
              {value + " " + "gp"}
            </option>
          ))}
        </Form.Select>
      </Col>

      <Col sm={8} className={style_column_content}>
        <div className={renderResult}>
          {randomItem.name} ({randomItem.value}
          {randomItem.currency})
        </div>
      </Col>
    </Row>
  );
};
