export interface IRollResult {
  diceName: string;
  diceType: DiceType;
  roll: number;
  modifiers?: number;
  result: number;
}

export enum DiceType {
  D100 = 100,
  D20 = 20,
  D12 = 12,
  D10 = 10,
  D8 = 8,
  D6 = 6,
  D4 = 4,
}

export interface IDie {
  name: string;
  value: number;
  type: DiceType;
}

export const Dices: Map<DiceType, IDie> = new Map([
  [DiceType.D100, { name: "D100", value: 100, type: DiceType.D100 }],
  [DiceType.D20, { name: "D20", value: 20, type: DiceType.D20 }],
  [DiceType.D12, { name: "D12", value: 12, type: DiceType.D12 }],
  [DiceType.D10, { name: "D10", value: 10, type: DiceType.D10 }],
  [DiceType.D8, { name: "D8", value: 8, type: DiceType.D8 }],
  [DiceType.D6, { name: "D6", value: 6, type: DiceType.D6 }],
  [DiceType.D4, { name: "D4", value: 4, type: DiceType.D4 }],
]);

export const DiceRollWithModifiers = (diceType: DiceType = DiceType.D20, modifiers: number[]): IRollResult => {
  let roll = DiceRoll(diceType);

  if (modifiers != null) {
    roll.modifiers = modifiers.reduce((a, b) => a + b, 0);
    roll.result += roll.modifiers;
  }

  return roll;
};

export const DiceRoll = (diceType: DiceType = DiceType.D20): IRollResult => {
  let dice = Dices.get(diceType);

  let rollResult: IRollResult = {
    diceName: dice!.name,
    diceType: diceType,
    roll: 0,
    result: 0,
  };
  rollResult.roll = Math.floor(Math.random() * (dice!.value - 1 + 1) + 1);
  rollResult.result = rollResult.roll;

  return rollResult;
};

export const DiceRollMany = (diceType: DiceType = DiceType.D20, rolls: number) => {
  let rollResults: IRollResult[] = [];
  for (var i = 0; i < rolls; i++) {
    rollResults.push(DiceRoll(diceType));
  }

  return rollResults;
};

export const DiceRollManyWithModifiers = (diceType: DiceType = DiceType.D20, rolls: number, modifiers: number[]) => {
  let rollResults: IRollResult[] = [];
  for (var i = 0; i < rolls; i++) {
    rollResults.push(DiceRollWithModifiers(diceType, modifiers));
  }

  return rollResults;
};
