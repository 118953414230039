import { FunctionComponent, useEffect } from "react";
import { useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useStore } from "react-redux";
import { style_dropdown, style_player_combattracker_col, style_player_combattracker_row_base } from "../../constants";
import { IStatusConditionType, StatusConditionTypes } from "../../types";

export const StatusConditionElement: FunctionComponent<{}> = () => {
  const [statusConditionState, setStatusConditionState] = useState<IStatusConditionType>(StatusConditionTypes[0]);
  const [noteState, setNoteState] = useState<string>("");
  const [turnsState, setTurnsState] = useState<number>(2);
  const [render, setRender] = useState<boolean>(true);

  const setStatusCondition = (newStatus: string) => {
    var newStatusCondition = StatusConditionTypes.find((e) => e.value === newStatus);
    if (newStatusCondition) {
      setStatusConditionState(newStatusCondition);
    }
  };

  /* subscribe to turn event */
  const store = useStore();
  const turnIncreaseEvent = () => {
    setTurnsState((t) => (t > 0 ? t - 1 : t));
  };
  useEffect(() => {
    store.subscribe(turnIncreaseEvent);
  }, []);

  useEffect(() => {
    if (turnsState < 1) {
      setRender(false);
    }
  }, [turnsState]);

  if (!render) {
    return null;
  }

  return (
    <Row className={style_player_combattracker_row_base + " bg-red-1"}>
      <Col sm={1} className={style_player_combattracker_col}></Col>
      <Col sm={2} className={style_player_combattracker_col}>
        <Form.Select
          className={style_dropdown}
          value={statusConditionState.value}
          onChange={(e) => setStatusCondition(e.currentTarget.value)}
        >
          {StatusConditionTypes.map((statusCondition) => (
            <option key={statusCondition.value} value={statusCondition.value}>
              {statusCondition.displayName}
            </option>
          ))}
        </Form.Select>
      </Col>
      <Col sm={1} className={style_player_combattracker_col}></Col>

      <Col sm={2} className={style_player_combattracker_col}>
        <InputGroup>
          <InputGroup.Text style={{ minWidth: 60, fontSize: 13 }}>{"Turns left"}</InputGroup.Text>
          <Form.Control
            value={turnsState}
            type="number"
            placeholder=""
            onChange={(e) => setTurnsState(parseInt(e.currentTarget.value))}
          />
        </InputGroup>
      </Col>
      <Col sm={6} className={style_player_combattracker_col}>
        <Form.Control
          value={noteState}
          type="text"
          placeholder="Notes"
          onChange={(e) => setNoteState(e.currentTarget.value)}
        />
      </Col>
    </Row>
  );
};
