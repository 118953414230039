import { FunctionComponent } from "react";
import { OverlayTrigger, Tooltip, Image } from "react-bootstrap";

export const ImageTooltip: FunctionComponent<{
  imageUrl?: string;
  toolTipType: ToolTipType;
  text?: JSX.Element;
  styling?: string;
}> = ({ imageUrl, toolTipType, text, styling }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>{text ?? ToolTipTypes.find((t) => t.type === toolTipType)?.toolTipText}</Tooltip>}
    >
      <Image
        src={imageUrl ?? ToolTipTypes.find((t) => t.type === toolTipType)?.icon}
        width="25"
        className={styling ?? ""}
      />
    </OverlayTrigger>
  );
};

export enum ToolTipType {
  MaxCurrentHp,
  RolledInitiative,
  Initiative,
  ArmorClass,
  DealDamage,
  SpellSaveDc,
  PassivePerception,
  HitPoints,
  DiceRoll,
}

export interface IToolTipType {
  type: ToolTipType;
  toolTipText: string;
  icon?: string;
}

export const ToolTipTypes: IToolTipType[] = [
  { type: ToolTipType.MaxCurrentHp, toolTipText: "max HP / current HP", icon: "img/roundtracker/heart.svg" },
  { type: ToolTipType.RolledInitiative, toolTipText: "rolled initiative", icon: "img/roundtracker/stopwatch.svg" },
  { type: ToolTipType.Initiative, toolTipText: "initiative", icon: "img/roundtracker/stopwatch.svg" },
  { type: ToolTipType.ArmorClass, toolTipText: "armor class (AC)", icon: "img/roundtracker/shield.svg" },
  { type: ToolTipType.DealDamage, toolTipText: "deal damage", icon: "img/roundtracker/sword.svg" },
  { type: ToolTipType.SpellSaveDc, toolTipText: "spell save DC", icon: "img/roundtracker/magic-wand.svg" },
  { type: ToolTipType.PassivePerception, toolTipText: "passive perception", icon: "img/eye.png" },
  { type: ToolTipType.HitPoints, toolTipText: "hit points", icon: "img/roundtracker/heart.svg" },
  { type: ToolTipType.DiceRoll, toolTipText: "roll dice", icon: "img/roundtracker/d20.png" },
];
