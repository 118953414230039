import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Col, Form, Row, Button, InputGroup } from "react-bootstrap";
import { ImageTooltip, ToolTipType } from "..";
import { PIActionType, PlayerInstanceContext, PlayerInstanceContract } from "../../04_Stores";
import { style_vertical_center } from "../../constants";
import { PlayerInstance } from "../../types";

export const RollInitiativePlayer: FunctionComponent<{ player: PlayerInstance }> = ({ player }) => {
  const [players, dispatchPlayers] = useContext<PlayerInstanceContract>(PlayerInstanceContext);

  const [initiative, setInitiative] = useState<number>(0);
  const [hitpoints, setHitPoints] = useState<number>(0);

  useEffect(() => {
    setInitiative(player.rolledInitiative);
    setHitPoints(player.currentHp);
  }, []);

  useEffect(() => {
    player.rolledInitiative = initiative;
  }, [initiative]);

  useEffect(() => {
    player.currentHp = hitpoints;
  }, [hitpoints]);

  const deletePlayer = () => {
    dispatchPlayers({
      type: PIActionType.Remove,
      payload: [player],
    });
  };

  return (
    <Row className="mb-2 me-1">
      {/* player name */}
      <Col className={style_vertical_center} sm={4}>
        {player.playerBase.characterName + " (" + player.playerBase.playerName + ")"}
      </Col>

      {/* passive perception */}
      <Col sm={1} className={style_vertical_center}>
        <ImageTooltip toolTipType={ToolTipType.PassivePerception} styling="me-2"></ImageTooltip>
        {player.playerBase.passivePerception}
      </Col>

      {/* armor class */}
      <Col sm={1} className={style_vertical_center}>
        <ImageTooltip toolTipType={ToolTipType.ArmorClass} styling="me-2"></ImageTooltip>
        {player.playerBase.armorClass}
      </Col>

      {/* spell save dc */}
      <Col sm={1} className={style_vertical_center}>
        <ImageTooltip toolTipType={ToolTipType.SpellSaveDc} styling="me-2"></ImageTooltip>
        {player.playerBase.spellSaveDC}
      </Col>

      {/* hit points */}
      <Col sm={2}>
        <InputGroup>
          <InputGroup.Text>
            <ImageTooltip toolTipType={ToolTipType.HitPoints}></ImageTooltip>
          </InputGroup.Text>
          <Form.Control
            type="number"
            value={hitpoints}
            placeholder=""
            onChange={(e) => setHitPoints(parseInt(e.currentTarget.value))}
          />
        </InputGroup>
      </Col>

      {/* rolled initiative*/}
      <Col sm={2}>
        <InputGroup>
          <InputGroup.Text>
            <ImageTooltip toolTipType={ToolTipType.RolledInitiative}></ImageTooltip>
          </InputGroup.Text>
          <Form.Control
            type="number"
            value={initiative}
            placeholder=""
            onChange={(e) => setInitiative(parseInt(e.currentTarget.value))}
          />
        </InputGroup>
      </Col>

      {/* remove player */}
      <Col sm={1}>
        <Button
          variant="outline-secondary"
          onClick={() => {
            deletePlayer();
          }}
        >
          x
        </Button>
      </Col>
    </Row>
  );
};
