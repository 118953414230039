import CryptoJS from "crypto-js";

const config = {
  mode: CryptoJS.mode.CBC,
  padding: CryptoJS.pad.Pkcs7,
};

export const AESencrypt = (value: string, key: string): string => {
  const cipher = CryptoJS.AES.encrypt(value, key, config);
  return cipher.toString();
};

export const AESdecrypt = (cipher: string, key: string): string => {
  const bytes = CryptoJS.AES.decrypt(cipher.toString(), key);
  return bytes.toString(CryptoJS.enc.Utf8);
};
