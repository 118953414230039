import { Container } from "react-bootstrap";
import { RandomizerType } from "../../api";
import { SimpleRandomizer } from "../../03_components";
import { style_container } from "../../constants";
import { FunctionComponent } from "react";

export const CombatRoleplayRandomizer: FunctionComponent<{}> = () => {
  return (
    <Container className={style_container} fluid>
      <SimpleRandomizer
        randomizerType={RandomizerType.Combat_Hitarea_Humanoid}
        randomizerName="Hit Area Humanoid"
        buttonVariant="sec-cyan"
      ></SimpleRandomizer>
      <SimpleRandomizer
        randomizerType={RandomizerType.Combat_Hitarea_Monster}
        randomizerName="Hit Area Monster"
        buttonVariant="sec-cyan"
      ></SimpleRandomizer>
      <SimpleRandomizer
        randomizerType={RandomizerType.Combat_Injury}
        randomizerName="Bad Injury"
        buttonVariant="sec-cyan"
      ></SimpleRandomizer>
      {/** <MonsterRandomizer
        randomizerType={RandomizerType.Combat_Monster}
        randomizerName="Monster Personality"
        buttonVariant="warning"
      ></MonsterRandomizer>*/}
    </Container>
  );
};
