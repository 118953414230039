import { FunctionComponent, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { RollInitiative } from "./RollInitiative";
import { RoundTracker } from "./RoundTracker";

export const InitiativeTracker: FunctionComponent<{}> = () => {
  const [initiativeRolled, setInitiativeRolled] = useState<boolean>(false);

  useEffect(() => {}, [initiativeRolled]);

  return (
    <Container fluid>
      {initiativeRolled ? (
        <RoundTracker></RoundTracker>
      ) : (
        <RollInitiative startCombat={setInitiativeRolled}></RollInitiative>
      )}
    </Container>
  );
};
