import { StatusCondition } from ".";

export interface ICampaign {
  name: string;
  players: IPlayerBase[];
}

export interface IPlayerBase {
  characterName: string;
  playerName: string;
  /* base */
  level: number;
  class: string;
  race: string;
  /* stats */
  armorClass: number;
  maxHp: number;
  spellSaveDC: number;
  /*skills */
  passivePerception: number;
  passiveInvestigation: number;
  passiveInsight: number;
  /* */
}

export interface IStatusCondition {
  statusCondition: StatusCondition;
  turnsRemaining: number;
  notes: string;
}

export interface IStatusConditionType {
  displayName: string;
  value: string;
  icon: string;
}

export interface ICombatTrackerCreature {
  creatureName: string;
  isPlayer: boolean;
}

/* Initiativetracker */
export interface IEncounter {
  name: string;
  creatures: IEncounterMonster[];
}

export interface IEncounterMonster {
  creatureName: string;
  amount: number;
}

export interface IMonsterInstance {
  id: string;
  name: string;
  color: ITokenColor;
  maxHP: number;
  currentHP: number;
  rolledInitiative: number;
  monsterBase: IMonsterBase;

  //functions
  buildName(): void;
  regenerateMonsterHealth(randomHealth: boolean): void;
  rollNewInitiative(): void;
  getAbilityScore(score: string): number;
}

export interface IMonsterBase {
  name: string;
  size: string;
  group: string;
  type: {
    type: string;
    tags: string[];
  };
  alignment: string;

  ac: { [key: string]: number };
  hp: {
    average: number;
    formula_multiplier: number;
    formula_dice_type: number;
    formula_modifier: number;
    special: string;
  };
  speed: { [key: string]: number };
  abilityScores: IAbilityScore[];
  skills: { [key: string]: number };
  passivePerception: number;
  savingThrows: { [key: string]: number };

  languages: string[];
  environments: string[];
  challengeRating: { [key: string]: number };
  traits: { [key: string]: string };
  senses: string[];

  damageImmunities: { [key: string]: string };
  damageResistances: { [key: string]: string };
  conditionImmunities: string[];
  damageVulnerabilities: { [key: string]: string };

  spellcasting: ISpellCasting[];
}

export interface IAbilityScore {
  ability: string;
  score: number;
  modifier: number;
}

export interface ISpellCasting {
  type: string;
  spellSaveDc: number;
  spellAttackModifier: number;
  spellcastingAbility: string;
}

export interface ITokenColor {
  hex: string;
  bgHex: string;
  index: number;
  name: string;
  whiteText: boolean;
}

export const TokenColorArray: ITokenColor[] = [
  { hex: "#e6482e", bgHex: "#eb6c57", index: 0, name: "Red", whiteText: false },
  { hex: "#f47e1b", bgHex: "#f69748", index: 1, name: "Orange", whiteText: false },
  { hex: "#f4b41b", bgHex: "#f6c348", index: 2, name: "Yellow", whiteText: false },
  { hex: "#b6d53c", bgHex: "#c4dd62", index: 3, name: "GreenLight", whiteText: false },
  { hex: "#397b44", bgHex: "#4da85c", index: 4, name: "GreenDark", whiteText: true },
  { hex: "#28ccdf", bgHex: "#52d6e5", index: 5, name: "BlueLight", whiteText: false },
  { hex: "#3978a8", bgHex: "#5494c5", index: 6, name: "BlueDark", whiteText: true },
  { hex: "#8e478c", bgHex: "#af60ad", index: 7, name: "Violett", whiteText: true },
  { hex: "#ffffff", bgHex: "#ffffff", index: 8, name: "White", whiteText: false },
  { hex: "#eea160", bgHex: "#f1b37f", index: 9, name: "BrownLight", whiteText: false },
  { hex: "#7a444a", bgHex: "#a35a62", index: 10, name: "BrownDark", whiteText: true },
  { hex: "#a0938e", bgHex: "#b3a8a4", index: 11, name: "Gray", whiteText: true },
  { hex: "#302c2e", bgHex: "#5b5357", index: 12, name: "Black", whiteText: true },
];

export const StatusConditionTypes: IStatusConditionType[] = [
  { displayName: "Blinded 👁", value: "Blinded", icon: "👁" },
  { displayName: "Charmed ❤️", value: "Charmed", icon: "❤️" },
  { displayName: "Deafened 👂🏻", value: "Deafened", icon: "👂🏻" },
  { displayName: "Frightened 👻", value: "Frightened", icon: "👻" },
  { displayName: "Grappled 🤼", value: "Grappled", icon: "🤼" },
  { displayName: "Incapacitated 😵", value: "Incapacitated", icon: "😵" },
  { displayName: "Invisible 🥷", value: "Invisible", icon: "🥷" },
  { displayName: "Paralyzed ⚡️", value: "Paralyzed", icon: "⚡️" },
  { displayName: "Petrified 🗿", value: "Petrified", icon: "🗿" },
  { displayName: "Poisoned 🤢", value: "Poisoned", icon: "🤢" },
  { displayName: "Prone 🙇", value: "Prone", icon: "🙇" },
  { displayName: "Restrained ⛓", value: "Restrained", icon: "⛓" },
  { displayName: "Stunned 😖", value: "Stunned", icon: "😖" },
  { displayName: "Suffocating 😨", value: "Suffocating", icon: "😨" },
  { displayName: "Unconscious 🆘", value: "Unconscious", icon: "🆘" },
];
