import { Container } from "react-bootstrap";
import { ArtValues, GemstoneValues, RandomizerType } from "../../api";
import { MagicItemRandomizer, SimpleRandomizer } from "../../03_components";
import { ItemsByValueRandomizer } from "../../03_components";
import { style_container } from "../../constants";
import { FunctionComponent } from "react";

export const ItemRandomizer: FunctionComponent<{}> = () => {
  return (
    <Container className={style_container} fluid>
      <SimpleRandomizer
        randomizerType={RandomizerType.Item_Junk}
        randomizerName="Junk Item"
        buttonVariant="sec-cyan"
      ></SimpleRandomizer>
      <SimpleRandomizer
        randomizerType={RandomizerType.Item_Mundane}
        randomizerName="Mundane Item"
        buttonVariant="sec-cyan"
      ></SimpleRandomizer>
      <ItemsByValueRandomizer
        randomizerType={RandomizerType.Item_Gemstone}
        randomizerName="Gemstone by Value"
        buttonVariant="warning"
        itemValues={GemstoneValues}
      ></ItemsByValueRandomizer>
      <ItemsByValueRandomizer
        randomizerType={RandomizerType.Item_Art}
        randomizerName="Art by Value"
        buttonVariant="warning"
        itemValues={ArtValues}
      ></ItemsByValueRandomizer>
      <MagicItemRandomizer
        randomizerType={RandomizerType.Item_By_Rarity}
        randomizerName="Magic Item By Rarity"
        buttonVariant="success"
      ></MagicItemRandomizer>
    </Container>
  );
};
