import { FunctionComponent, useContext, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { CreatureCombatTracker } from "../../03_components";
import { RoundTrackerContext, RoundTrackerContract, RTActionType } from "../../04_Stores";
import { style_player_combattracker_container } from "../../constants";

export const MonsterCombatTracker: FunctionComponent<{}> = () => {
  const [monsters, setMonsters] = useState<JSX.Element[]>([]);
  const [newMonsterName, setNewMonsterName] = useState<string>("");

  const [roundTracker, setRoundTracker] = useContext<RoundTrackerContract>(RoundTrackerContext);
  const addMonster = () => {
    let MonsterComponent = (
      <CreatureCombatTracker
        creature={{ creatureName: newMonsterName, isPlayer: false }}
        key={Date.now()}
      ></CreatureCombatTracker>
    );
    setMonsters((oldArray) => [...oldArray, MonsterComponent]);
    setNewMonsterName("");
  };

  const resetMonster = () => {
    setMonsters([]);
  };

  return (
    <Container className={style_player_combattracker_container} fluid>
      <Container className="d-flex" fluid>
        <Row className="flex-fill">
          <Col className="d-flex justify-content-end" sm={6}>
            <Button variant="success" onClick={() => setRoundTracker({ type: RTActionType.Increase })}>
              Round Over
            </Button>
          </Col>
          <Col className="d-flex" sm={1}>
            <Button variant="dark" onClick={() => addMonster()}>
              Add Monster
            </Button>
          </Col>
          <Col sm={3}>
            <Form.Control
              type="text"
              value={newMonsterName}
              placeholder="Monster Name"
              onChange={(e) => setNewMonsterName(e.currentTarget.value)}
              onKeyUp={(e) => (e.key === "Enter" ? addMonster() : null)}
            />
          </Col>
          <Col className="d-flex justify-content-end" sm={2}>
            <Button variant="secondary" onClick={() => resetMonster()}>
              Reset
            </Button>
          </Col>
        </Row>
      </Container>

      {monsters.map((monster) => monster)}
    </Container>
  );
};
