import { IPlayerBase } from "../Models";

export class PlayerInstance {
  playerBase: IPlayerBase = {} as IPlayerBase;

  /* aditional props */
  id!: string;
  rolledInitiative: number = 0;
  currentHp: number;

  constructor(pb: IPlayerBase) {
    this.playerBase = pb;
    this.currentHp = pb.maxHp;
    this.buildId();
  }

  buildId() {
    this.id = this.playerBase.characterName + Date.now().toString();
  }
}
