import { callApi } from "../ApiClientBase";
import { RandomizerType } from "./RandomizerType";

export interface IRandomTableEntry {
  value: string;
}

export interface IRandomNpc {
  race: string;
  gender: string;
  firstName: string;
  hairColor: IColor;
  eyeColor: IColor;
  skinColor: IColor;
}

export interface IColor {
  colorName: string;
  hexValue: string;
}

export interface IMonster {
  name: string;
  relationship: string;
  personality: string;
}

export const getRandomSimpleRollApi = (randomizerType: RandomizerType): Promise<IRandomTableEntry> => {
  switch (randomizerType) {
    case RandomizerType.Item_Junk: {
      return callApi<IRandomTableEntry>("item/junk");
    }
    case RandomizerType.Item_Mundane: {
      return callApi<IRandomTableEntry>("item/mundane");
    }
    case RandomizerType.Combat_Injury: {
      return callApi<IRandomTableEntry>("combat/injury");
    }
    case RandomizerType.Combat_Hitarea_Humanoid: {
      return callApi<IRandomTableEntry>("combat/hitarea_humanoid");
    }
    case RandomizerType.Combat_Hitarea_Monster: {
      return callApi<IRandomTableEntry>("combat/hitarea_monster");
    }
    case RandomizerType.Combat_Random_Player: {
      const players = ["Beomar", "Sairi", "Thoros", "Sorlak", "Alegria"];
      return new Promise((resolve) => {
        resolve({ value: players[Math.floor(Math.random() * players.length)] });
      });
    }
  }

  return new Promise((resolve) => {
    resolve({ value: "" });
  });
};

export const getRandomNpcApi = (randomizerType: RandomizerType): Promise<IRandomNpc> => {
  return callApi<IRandomNpc>("npc");
};

/*
export const getRandomMagicItemByRarityApi = (rarity: string): Promise<IItem> => {
  return callApi<IItem>("item/magic/" + rarity);
};

export const getRandomItemByValueApi = (value: number, randomizerType: RandomizerType): Promise<IGemstone> => {
  switch (randomizerType) {
    case RandomizerType.Item_Gemstone: {
      return callApi<IGemstone>("item/gemstone/" + value);
    }
    case RandomizerType.Item_Art: {
      return callApi<IGemstone>("item/art/" + value);
    }
  }

  return new Promise((resolve) => {
    resolve({ value: 0, currency: "", name: "" });
  });
};
*/
export const getRandomMonsterApi = (randomizerType: RandomizerType): Promise<IMonster> => {
  return callApi<IMonster>("combat/monster_character");
};
