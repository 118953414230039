import { useEffect, useState, useContext, FunctionComponent } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  RollInitiativeAddMonster,
  RollInitiativeAddPlayer,
  RollInitiativeMonster,
  RollInitiativePlayer,
  RollPreCombatChecks,
} from "../../03_components";
import {
  MIActionType,
  MonsterInstanceContext,
  MonsterInstanceContract,
  PIActionType,
  PlayerInstanceContext,
  PlayerInstanceContract,
} from "../../04_Stores";
import {
  readEncountersFromJson,
  readMonstersIDROTFfromJson,
  readMonstersMMfromJson,
  readMonstersVGMfromJson,
  readCampaignsFromJson,
} from "../../api";
import { style_player_combattracker_row_base } from "../../constants";
import { MonsterInstance, PlayerInstance } from "../../types";
import { ICampaign, IEncounter, IMonsterBase, IMonsterInstance, TokenColorArray } from "../../types/Models";

const CUSTOM_ENCOUNTER = "CUSTOM_ENCOUNTER";
const CUSTOM_CAMPAIGN = "CUSTOM_CAMPAIGN";

export const RollInitiative: FunctionComponent<{ startCombat: React.Dispatch<React.SetStateAction<boolean>> }> = ({
  startCombat,
}) => {
  const [randMonsterHealth, setRandMonsterHealth] = useState<boolean>(false);
  const [encounters, setEncounters] = useState<IEncounter[]>([]);
  const [selectedEncounter, setSelectedEncounter] = useState<string>("default");
  const [baseMonsters, setBaseMonsters] = useState<IMonsterBase[]>([]);
  const [monsters, dispatchMonsters] = useContext<MonsterInstanceContract>(MonsterInstanceContext);
  const [players, dispatchPlayers] = useContext<PlayerInstanceContract>(PlayerInstanceContext);
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<string>(CUSTOM_CAMPAIGN);

  //when initializing page, load players and encounters
  useEffect(() => {
    resetPlayers();
    readCampaignsFromJson().then((result) => setCampaigns(result));
    readEncountersFromJson().then((result) => setEncounters(result));
    readMonstersMMfromJson().then((result) => setBaseMonsters((oldarr) => [...oldarr, ...result]));
    readMonstersIDROTFfromJson().then((result) => setBaseMonsters((oldarr) => [...oldarr, ...result]));
    readMonstersVGMfromJson().then((result) => setBaseMonsters((oldarr) => [...oldarr, ...result]));
  }, []);

  //reset Players
  const resetPlayers = () => {
    if (selectedCampaign === CUSTOM_CAMPAIGN) {
      dispatchPlayers({
        type: PIActionType.ReplaceAll,
        payload: [],
      });
      return;
    }

    let playerInstances: PlayerInstance[] = [];
    let campagin = campaigns.find((c) => c.name === selectedCampaign);
    if (campagin) {
      campagin.players.forEach((p) => {
        playerInstances.push(new PlayerInstance(p));
      });
      dispatchPlayers({
        type: PIActionType.ReplaceAll,
        payload: playerInstances,
      });
    }
  };

  //on campaign change
  useEffect(() => {
    dispatchPlayers({
      type: PIActionType.ReplaceAll,
      payload: [],
    });

    if (selectedCampaign !== CUSTOM_CAMPAIGN) {
      resetPlayers();
    }
  }, [selectedCampaign]);

  //on encounter change
  useEffect(() => {
    dispatchMonsters({
      type: MIActionType.Reset,
      payload: [],
    });

    if (selectedEncounter !== CUSTOM_ENCOUNTER) {
      generateMonsterInstances();
    }
  }, [selectedEncounter]);

  //generate monsters
  const generateMonsterInstances = () => {
    //get selected encounter
    let encounterMonsters = encounters.find((e) => e.name === selectedEncounter)?.creatures || null;
    if (encounterMonsters === null) return;

    let colorIndex = 0;
    //generate MonsterInstances
    let monsterInstanceArray: IMonsterInstance[] = [];

    encounterMonsters.forEach((monsterEncounter) => {
      let baseMonster = baseMonsters.find((m) => m.name === monsterEncounter.creatureName) || null;

      for (let i = 0; i < monsterEncounter.amount; i++) {
        monsterInstanceArray.push(
          new MonsterInstance(baseMonster!, randMonsterHealth, monsterInstanceArray.length, colorIndex)
        );
        colorIndex = colorIndex === TokenColorArray.length - 1 ? 0 : colorIndex + 1;
      }
    });

    dispatchMonsters({
      type: MIActionType.ReplaceAll,
      payload: monsterInstanceArray,
    });
  };

  //recalculate monster health
  useEffect(() => {
    monsters.data.forEach((s) => s.regenerateMonsterHealth(randMonsterHealth));
    dispatchMonsters({
      type: MIActionType.UpdateInitialize,
      payload: [],
    });
  }, [randMonsterHealth]);

  return (
    <Container className="pt-4 d-flex flex-column" fluid>
      {/* 1.Row - choose encounter, general config */}
      <Row className="mb-3">
        <Col className="d-flex justify-content-center" sm={12}>
          <Button
            variant="sec-green"
            onClick={() => {
              startCombat(true);
            }}
          >
            start combat
          </Button>
        </Col>
      </Row>

      {/* 2.Row - Players*/}
      <Row>
        <Col className={style_player_combattracker_row_base + " bg-cyan-1 text-center fs-5"} sm={12}>
          Players
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="d-flex flex-column mb-4" sm={9}>
          {/* add player */}
          <RollInitiativeAddPlayer></RollInitiativeAddPlayer>

          {/* player table */}
          {players.data.map((player) => (
            <RollInitiativePlayer player={player} key={player.id}></RollInitiativePlayer>
          ))}
        </Col>

        {/* player settings */}
        <Col sm={3}>
          {/* select campagin */}
          <Row>
            <Col className={style_player_combattracker_row_base + " bg-gray-2 text-center"}>set campagin</Col>
          </Row>
          <Row className="mb-3">
            <Form.Select value={selectedCampaign} onChange={(e) => setSelectedCampaign(e.currentTarget.value)}>
              <option key={CUSTOM_CAMPAIGN} value={CUSTOM_CAMPAIGN}>
                [custom campaign]
              </option>
              {campaigns.map((campaigns) => (
                <option key={campaigns.name} value={campaigns.name}>
                  {campaigns.name}
                </option>
              ))}
            </Form.Select>
          </Row>

          {/* reset player instances */}
          <Row>
            <Col className={style_player_combattracker_row_base + " bg-gray-2 text-center"}>reset player rooster</Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Button variant="outline-secondary" onClick={() => resetPlayers()}>
              reset players
            </Button>
          </Row>
        </Col>
      </Row>

      {/* 4.Row - Monsters */}
      <Row>
        <Col className={style_player_combattracker_row_base + " bg-indigo-1 text-center fs-5"}>Enemies</Col>
      </Row>
      <Row>
        <Col className="d-flex flex-column" sm={9}>
          {/* add monster */}
          <RollInitiativeAddMonster
            randMonsterHealth={randMonsterHealth}
            monsterBases={baseMonsters}
          ></RollInitiativeAddMonster>

          {/* monster table */}
          {monsters.data.map((monster) => (
            <RollInitiativeMonster monsterId={monster.id} key={monster.id}></RollInitiativeMonster>
          ))}
        </Col>

        <Col className="d-flex flex-column" sm={3}>
          {/* choose encounter*/}
          <Row>
            <Col className={style_player_combattracker_row_base + " bg-gray-2 text-center"}>set encounter</Col>
          </Row>
          <Row className="mb-3">
            <Form.Select value={selectedEncounter} onChange={(e) => setSelectedEncounter(e.currentTarget.value)}>
              <option key={CUSTOM_ENCOUNTER} value={CUSTOM_ENCOUNTER}>
                [custom encounter]
              </option>
              {encounters.map((encounter) => (
                <option key={encounter.name} value={encounter.name}>
                  {encounter.name}
                </option>
              ))}
            </Form.Select>
          </Row>

          {/* hp calculation */}
          <Row>
            <Col className={style_player_combattracker_row_base + " bg-gray-2 text-center"}>max HP calculation</Col>
          </Row>
          <Row>
            <Col className="d-flex flex-row justify-content-center" sm={12}>
              <Form.Check
                inline
                label="static"
                type="radio"
                checked={!randMonsterHealth}
                onChange={() => {
                  setRandMonsterHealth(false);
                }}
              />
              <Form.Check
                inline
                label="random"
                type="radio"
                checked={randMonsterHealth}
                onChange={() => {
                  setRandMonsterHealth(true);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {/* 5.Row, Roll Checks */}
      <RollPreCombatChecks></RollPreCombatChecks>
    </Container>
  );
};
