import { FunctionComponent, useContext, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import {
  MonsterInstanceContext,
  MonsterInstanceContract,
  PlayerInstanceContext,
  PlayerInstanceContract,
} from "../../04_Stores";

const colum_title_style = "text-center d-flex justify-content-center align-items-center py-1 text-muted";

export const RollPreCombatChecks: FunctionComponent<{}> = () => {
  const [players, dispatchPlayers] = useContext<PlayerInstanceContract>(PlayerInstanceContext);
  const [monsters, dispatchMonsters] = useContext<MonsterInstanceContract>(MonsterInstanceContext);
  const [monsterStealth, setMonsterStealth] = useState<string>("");
  const [monsterSurprise, setMonsterSurprise] = useState<string>("");
  const [lowestMonsterSurprise, setLowestMonsterSurprise] = useState<number>(0);

  const rollMonsterStealth = () => {
    setMonsterStealth("Surprised Players: ");

    let rolls: number[] = [];
    monsters.data.forEach((m) => {
      let hasStealth = m.monsterBase.skills ? "stealth" in m.monsterBase.skills : false;
      let modifier = hasStealth
        ? m.monsterBase.skills["stealth"]
        : m.monsterBase.abilityScores.find((as) => as.ability === "dex")?.modifier;

      rolls.push(rollD20(modifier!));
    });

    let lowestRoll = Math.min(...rolls);

    players.data.forEach((p) => {
      if (p.playerBase.passivePerception < lowestRoll) {
        setMonsterStealth((ms) => ms + p.playerBase.characterName + ", ");
      }
    });

    console.log("Monster rolls: " + rolls);
  };

  const checkMonsterSurprise = () => {
    setMonsterSurprise("Surprised Monsters: ");
    monsters.data.forEach((monster) => {
      if (monster.monsterBase.passivePerception < lowestMonsterSurprise) {
        setMonsterSurprise((ms) => ms + monster.name + ", ");
      }
    });
  };

  return (
    <>
      <Row className="mt-5">
        <Col sm={12} className={colum_title_style + " bg-gray-2"}>
          Determine Surprise
        </Col>
      </Row>
      <Row className="mt-1">
        <Col sm={2} className="d-flex">
          <Button variant="light-indigo" className="flex-fill" onClick={() => rollMonsterStealth()}>
            <b>Monsters:</b> Roll stealth
          </Button>
        </Col>
        <Col sm={10} className="py-2">
          {monsterStealth}
        </Col>
      </Row>
      <Row className="mt-1">
        <Col sm={2} className="d-flex">
          <Button variant="sec-cyan" className="flex-fill" onClick={() => checkMonsterSurprise()}>
            <b>Players:</b> Check for Surprise
          </Button>
        </Col>
        <Col sm={1}>{"Lowest Player Stealth Roll"}</Col>
        <Col sm={2} className="d-flex">
          <Form.Control
            type="number"
            value={lowestMonsterSurprise}
            placeholder=""
            onChange={(e) => setLowestMonsterSurprise(parseInt(e.currentTarget.value))}
          />
        </Col>
        <Col sm={5} className="py-2">
          {monsterSurprise}
        </Col>
      </Row>
    </>
  );
};

const rollD20 = (modifier: number) => {
  return Math.floor(Math.random() * (20 - 1 + 1) + 1) + modifier;
};
