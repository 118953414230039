export interface IQuizForm {
  owner: string;
  song_chill: ISong;
  song_pickup: ISong;
  song_bonus: ISong;
  song_genre: ISong;
  song_secret: ISong;
  song_hate: ISong;
  song_memories: ISong;
  song_party: ISong;
}

export const SongCategory: string[] = ["chill", "pickup", "bonus", "genre", "secret", "hate", "memories", "party"];

export interface IQuizAnswerTemplate {
  category: string;
  answers: ISongAnswer[];
}

export interface ISelectOption {
  label: string;
  value: string;
}

export interface IQuizAnswer {
  owner: string;
  category: string;
  one: ISong;
  two: ISong;
  three: ISong;
  four: ISong;
  five: ISong;
  six: ISong;
  seven: ISong;
  eight: ISong;
  nine: ISong;
}

export interface ISong {
  song: string | null;
  start_time: string | null;
  category: string;
}

export interface ISongAnswer {
  song_name: string | null;
  song_owner: string | null;
  song_answer: string | null;
}

export const QuizNames: string[] = [
  "Larissa",
  "Vanessa",
  "Lukas",
  "Vincent",
  "Zoltan",
  "Krzysztof",
  "Matthias",
  "David",
  "Michael",
];
