import { FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Container, Row, Image } from "react-bootstrap";
import {
  style_container,
  style_player_combattracker_col,
  style_player_combattracker_row,
  style_player_combattracker_row_base,
} from "../../constants";
import { ICombatTrackerCreature } from "../../types";
import { MiscConditionElement } from "./MiscConditionElement";
import { StatusConditionElement } from "./StatusConditionElement";

const add_button_style = "text-white fw-bold";

export const CreatureCombatTracker: FunctionComponent<{ creature: ICombatTrackerCreature }> = ({ creature }) => {
  const [statusConditions, setStatusConditions] = useState<JSX.Element[]>([]);
  const [miscConditions, setMiscConditions] = useState<JSX.Element[]>([]);
  const [spellcastingTracker, setSpellcastingTracker] = useState<JSX.Element[]>([]);
  const [imgName, setImgName] = useState<string>("");
  const [render, setRender] = useState<boolean>(true);

  useEffect(() => {
    setImgName("img/monsters/monster" + Math.floor(Math.random() * 24) + ".svg");
  }, []);

  const AppendStatusCondition = () => {
    let child = <StatusConditionElement key={Date.now()}></StatusConditionElement>;
    setStatusConditions((oldArray) => [...oldArray, child]);
  };

  const AppendMiscCondition = () => {
    let child = <MiscConditionElement placeholderText="Condition Description" key={Date.now()}></MiscConditionElement>;
    setMiscConditions((oldArray) => [...oldArray, child]);
  };

  const AppendSpellcastingTracker = () => {
    let child = <MiscConditionElement placeholderText="Spell Name" key={Date.now()}></MiscConditionElement>;
    setSpellcastingTracker((oldArray) => [...oldArray, child]);
  };

  const removeCreature = () => {
    setRender(false);
  };

  if (!render) {
    return null;
  }

  return (
    <Container className={style_container} fluid>
      <Row className={style_player_combattracker_row_base}>
        <Col sm={11} className={style_player_combattracker_col + " h3"}>
          <span className="badge bg-dark text-white px-3 py-2">
            {!creature.isPlayer ? <img width="55px" src={imgName} alt="monster" /> : ""}
            <span className="ps-2">{creature.creatureName}</span>
          </span>
        </Col>
        <Col sm={1} className={style_player_combattracker_col + " justify-content-end"}>
          {!creature.isPlayer ? (
            <Button size="sm" variant="secondary" onClick={() => removeCreature()}>
              x
            </Button>
          ) : (
            ""
          )}
        </Col>
      </Row>

      {/* Concentrating on Spell */}
      <Row className={style_player_combattracker_row}>
        <Col sm={6} className={style_player_combattracker_col}>
          <Image src="img/wizard-hat.png" className="pe-2" /> Spell Concentration
        </Col>
        <Col sm={6} className={style_player_combattracker_col + " justify-content-end"}>
          <Button className={add_button_style} variant="sec-cyan" onClick={() => AppendSpellcastingTracker()}>
            + <Image width="25px" src="img/wizard-hat.png" />
          </Button>
        </Col>
      </Row>
      {spellcastingTracker.map((value) => value)}

      {/*Status conditions */}
      <Row className={style_player_combattracker_row}>
        <Col sm={6} className={style_player_combattracker_col}>
          <Image src="img/dead.png" className="pe-2" /> Status Conditions
        </Col>
        <Col sm={6} className={style_player_combattracker_col + " justify-content-end"}>
          <Button className={add_button_style} variant="sec-cyan" onClick={() => AppendStatusCondition()}>
            + <Image width="25px" src="img/dead.png" />
          </Button>
        </Col>
      </Row>
      {statusConditions.map((value) => value)}

      {/*misc Status conditions */}
      <Row className={style_player_combattracker_row}>
        <Col sm={6} className={style_player_combattracker_col}>
          <Image src="img/cloud.png" className="pe-2" /> Misc Conditions
        </Col>
        <Col sm={6} className={style_player_combattracker_col + " justify-content-end"}>
          <Button className={add_button_style} variant="sec-cyan" onClick={() => AppendMiscCondition()}>
            + <Image width="25px" src="img/cloud.png" />
          </Button>
        </Col>
      </Row>
      {miscConditions.map((value) => value)}
    </Container>
  );
};
