import { FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { style_vertical_center } from "../../constants";
import { IQuizAnswerTemplate, QuizNames } from "../../types";
import Select from "react-select";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { AESencrypt, db } from "../../utils";
import { SongAnswer } from "../../01_quiz/songAnswer";
import { useNavigate } from "react-router";

export const AnswersPage: FunctionComponent<{}> = () => {
  const alignCenter = "d-flex align-items-center justify-content-center flex-column p-5";
  const SELECT_PLACEHOLDER = { label: "-select your name-", value: "UNDEFINED" };
  const [answers, setAnswers] = useState<IQuizAnswerTemplate | null>(null);

  const [owner, setOwner] = useState<{ label: string; value: string }>(SELECT_PLACEHOLDER);
  const [ready, setReady] = useState<boolean>(false);

  const navigate = useNavigate();

  const selectNames: readonly { label: string; value: string }[] = [
    SELECT_PLACEHOLDER,
    ...QuizNames.map((value) => {
      return { label: value, value: value };
    }),
  ];

  const writeEntry = async () => {
    let entry = {
      category: "chill",
      answers: [
        {
          song_answer: null,
          song_name: "blau",
          song_owner: AESencrypt("Larissa", process.env.REACT_APP_CRYPTOJS_SECRET_KEY!),
        },
        {
          song_answer: null,
          song_name: "rot",
          song_owner: AESencrypt("Michael", process.env.REACT_APP_CRYPTOJS_SECRET_KEY!),
        },
        {
          song_answer: null,
          song_name: "grün",
          song_owner: AESencrypt("Matthias", process.env.REACT_APP_CRYPTOJS_SECRET_KEY!),
        },
        {
          song_answer: null,
          song_name: "gelb",
          song_owner: AESencrypt("David", process.env.REACT_APP_CRYPTOJS_SECRET_KEY!),
        },
        {
          song_answer: null,
          song_name: "pink",
          song_owner: AESencrypt("Vanessa", process.env.REACT_APP_CRYPTOJS_SECRET_KEY!),
        },
        {
          song_answer: null,
          song_name: "schwarz",
          song_owner: AESencrypt("Zoltan", process.env.REACT_APP_CRYPTOJS_SECRET_KEY!),
        },
        {
          song_answer: null,
          song_name: "violet",
          song_owner: AESencrypt("Vincent", process.env.REACT_APP_CRYPTOJS_SECRET_KEY!),
        },
        {
          song_answer: null,
          song_name: "braun",
          song_owner: AESencrypt("Krzysztof", process.env.REACT_APP_CRYPTOJS_SECRET_KEY!),
        },
        {
          song_answer: null,
          song_name: "gold",
          song_owner: AESencrypt("Lukas", process.env.REACT_APP_CRYPTOJS_SECRET_KEY!),
        },
      ],
    } as IQuizAnswerTemplate;

    try {
      const docRef = await addDoc(collection(db, "quizanswertemplate"), entry);
      console.log(docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const getQuizEntries = async () => {
    const q = query(collection(db, "quizanswertemplate"), where("category", "==", "chill"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setAnswers(doc.data() as IQuizAnswerTemplate);
    });
    setReady(true);
  };

  useEffect(() => {
    // writeEntry();
    getQuizEntries();
  }, []);

  useEffect(() => {
    console.log(answers);
  }, [answers]);

  if (!ready) {
    return null;
  }

  const writeQuzAnswersAsync = async () => {
    try {
      const docRef = await addDoc(collection(db, "quizanswers"), answers);
      console.log(docRef.id);
      navigate("/musicquiz/answers/submit" + docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const submit = async () => {};

  const setAnswer = (name: string, actualOwner: string) => {
    let updatedList = answers!.answers.map((item) => {
      if (item.song_owner === actualOwner) {
        return { ...item, song_answer: name }; //gets everything that was already in item, and updates "done"
      }
      return item; // else return unmodified item
    });

    setAnswers({ category: answers!.category, answers: updatedList });
  };

  return (
    <Container fluid className="p-5">
      <h1>Take your best guess</h1>
      <Row className={"mb-5 mt-4"}>
        <Col sm={4} />
        <Col sm={1} className={style_vertical_center}>
          <b>Your Name</b>
        </Col>

        <Col sm={3}>
          <Select value={owner} onChange={(e) => setOwner(e === null ? SELECT_PLACEHOLDER : e)} options={selectNames} />
        </Col>
      </Row>

      <Row className="mb-2">
        <Col sm={5} className="d-flex align-items-center justify-content-center">
          <b>Song</b>
        </Col>

        <Col sm={7} className="d-flex align-items-center justify-content-center">
          <b>Who do you think this song belongs to?</b>
        </Col>
      </Row>

      {answers?.answers.map((answer) => (
        <SongAnswer owner={answer.song_owner!} songname={answer.song_name!} setAnswers={setAnswer}></SongAnswer>
      ))}

      <div className="d-flex align-items-center justify-content-center mt-5">
        <Button className="w-25" variant="success" onClick={() => submit()}>
          Submit your answers
        </Button>
      </div>
    </Container>
  );
};
