import { Container } from "react-bootstrap";
import { RandomizerType } from "../../api";
import { PlayerRandomizer, SimpleRandomizer } from "../../03_components";
import { style_container } from "../../constants";
import { FunctionComponent } from "react";

export const CombatRandomizer: FunctionComponent<{}> = () => {
  return (
    <Container className={style_container} fluid>
      <SimpleRandomizer
        randomizerType={RandomizerType.Combat_CriticalMiss}
        randomizerName="Critical Miss"
        buttonVariant="danger"
      ></SimpleRandomizer>
      <SimpleRandomizer
        randomizerType={RandomizerType.Combat_CriticalHit}
        randomizerName="Critical Hit"
        buttonVariant="success"
      ></SimpleRandomizer>
      <SimpleRandomizer
        randomizerType={RandomizerType.Combat_SpellcastingMishap}
        randomizerName="Spellcasting Mishap"
        buttonVariant="warning"
      ></SimpleRandomizer>
      <PlayerRandomizer randomizerName="Random Player" buttonVariant="sec-cyan"></PlayerRandomizer>
    </Container>
  );
};
