import { FunctionComponent } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Image } from "react-bootstrap";
export const MusicQuizSubmitPage: FunctionComponent<{}> = () => {
  const alignCenter = "d-flex align-items-center justify-content-center flex-column p-5";
  const { docid } = useParams();

  return (
    <Container fluid className={alignCenter}>
      <h1 className="mt-4">Thanks! Your music list has been submitted</h1>

      <p>
        In case you made a mistake, give this ID to Shoto so he can delete the db-record: <b>{docid}</b>
      </p>

      <Image width="500px" src="/img/quiz/shoto.png" />
    </Container>
  );
};
