export enum RandomizerType {
  Combat_CriticalMiss,
  Combat_CriticalHit,
  Combat_SpellcastingMishap,
  Combat_Monster,
  Combat_Injury,
  Combat_Hitarea_Humanoid,
  Combat_Hitarea_Monster,
  Combat_Random_Player,
  Npc,
  Item_Junk,
  Item_Mundane,
  Item_Gemstone,
  Item_Art,
  Item_By_Rarity,
}
