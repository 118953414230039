import { FunctionComponent, useContext, useEffect, useState } from "react";
import { IRandomTableEntry, ITableEntry } from "../../api";
import { Row, Col, Button } from "react-bootstrap";
import { style_column_button, style_column_content, style_row } from "../../constants";
import { style_button } from "../../constants/styling";
import { PlayerInstanceContext, PlayerInstanceContract } from "../../04_Stores";

export const PlayerRandomizer: FunctionComponent<{ randomizerName: string; buttonVariant: string }> = ({
  randomizerName,
  buttonVariant,
}) => {
  const [entry, setEntry] = useState<IRandomTableEntry>({ value: "" });
  const [table, setTable] = useState<ITableEntry[]>([]);
  const [players, dispatchPlayers] = useContext<PlayerInstanceContract>(PlayerInstanceContext);

  //when initializing, load random roll table
  useEffect(() => {
    if (!players || !players.data) {
      return;
    }
    let playertable = players.data.map((p) => ({ text: p.playerBase.characterName } as ITableEntry));
    setTable(playertable);
  }, [players]);

  const getRandomRoll = () => {
    if (table.length === 0) {
      setEntry({ value: "There needs to be at least one player in the initiative tracker to make a roll" });
      return;
    }
    var randomEntry = table[Math.floor(Math.random() * table.length)];
    setEntry({ value: randomEntry.text });
  };

  return (
    <Row className={style_row}>
      <Col sm={2} className={style_column_button}>
        <Button className={style_button} variant={buttonVariant} onClick={() => getRandomRoll()}>
          {randomizerName}
        </Button>
      </Col>
      <Col sm={10} className={style_column_content}>
        <div>{entry.value}</div>
      </Col>
    </Row>
  );
};
