import { FunctionComponent, useContext, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Image } from "react-bootstrap";
import { ImageTooltip, ToolTipType } from "..";
import { PIAction, PIActionType, PlayerInstanceContext, PlayerInstanceContract } from "../../04_Stores";
import { style_vertical_center } from "../../constants";
import { PlayerInstance } from "../../types";

export const RollInitiativeAddPlayer: FunctionComponent<{}> = () => {
  const [players, dispatchPlayers] = useContext<PlayerInstanceContract>(PlayerInstanceContext);
  const [playerName, setPlayerName] = useState<string>("");
  const [characterName, setCharacterName] = useState<string>("");
  const [armorClass, setArmorClass] = useState<number>(0);
  const [spellSaveDc, setSpellSaveDc] = useState<number>(0);
  const [rolledInitiative, setRolledInitiative] = useState<number>(0);
  const [passivePerception, setPassivePerception] = useState<number>(0);
  const [hitpoints, setHitpoints] = useState<number>(0);

  return (
    <Row className="bg-gray-2 py-1 mb-2 d-flex me-1">
      <Col sm={1} className={style_vertical_center + " text-secondary"}>
        add player
      </Col>
      <Col sm={2}>
        <Row className="mb-1">
          <Form.Control
            value={characterName}
            type="text"
            placeholder="hero name"
            onChange={(e) => setCharacterName(e.currentTarget.value)}
          />
        </Row>
        <Row>
          <Form.Control
            value={playerName}
            type="text"
            placeholder="player name"
            onChange={(e) => setPlayerName(e.currentTarget.value)}
          />
        </Row>
      </Col>

      <Col sm={2}>
        <Row className="mb-1">
          <InputGroup>
            <InputGroup.Text>
              <ImageTooltip toolTipType={ToolTipType.PassivePerception}></ImageTooltip>
            </InputGroup.Text>
            <Form.Control
              value={passivePerception}
              type="number"
              placeholder=""
              onChange={(e) => setPassivePerception(parseInt(e.currentTarget.value))}
            />
          </InputGroup>
        </Row>

        <Row>
          <InputGroup>
            <InputGroup.Text>
              <ImageTooltip toolTipType={ToolTipType.SpellSaveDc}></ImageTooltip>
            </InputGroup.Text>
            <Form.Control
              value={spellSaveDc}
              type="number"
              placeholder=""
              onChange={(e) => setSpellSaveDc(parseInt(e.currentTarget.value))}
            />
          </InputGroup>
        </Row>
      </Col>

      <Col sm={2}>
        <InputGroup>
          <InputGroup.Text>
            <ImageTooltip toolTipType={ToolTipType.ArmorClass}></ImageTooltip>
          </InputGroup.Text>
          <Form.Control
            value={armorClass}
            type="number"
            placeholder="armor class"
            onChange={(e) => setArmorClass(parseInt(e.currentTarget.value))}
          />
        </InputGroup>
      </Col>
      <Col sm={2}>
        <InputGroup>
          <InputGroup.Text>
            <ImageTooltip toolTipType={ToolTipType.HitPoints}></ImageTooltip>
          </InputGroup.Text>
          <Form.Control
            value={hitpoints}
            type="number"
            placeholder=""
            onChange={(e) => setHitpoints(parseInt(e.currentTarget.value))}
          />
        </InputGroup>
      </Col>
      <Col sm={2}>
        <InputGroup>
          <InputGroup.Text>
            <ImageTooltip toolTipType={ToolTipType.RolledInitiative}></ImageTooltip>
          </InputGroup.Text>
          <Form.Control
            value={rolledInitiative}
            type="number"
            placeholder=""
            onChange={(e) => setRolledInitiative(parseInt(e.currentTarget.value))}
          />
        </InputGroup>
      </Col>
      <Col sm={1}>
        <Button
          className="flex-fill"
          variant="outline-secondary"
          onClick={() => {
            addNewPlayer(
              playerName,
              characterName,
              armorClass,
              spellSaveDc,
              rolledInitiative,
              passivePerception,
              hitpoints,
              dispatchPlayers
            );
          }}
        >
          add
        </Button>
      </Col>
    </Row>
  );
};

export const addNewPlayer = (
  playerName: string,
  characterName: string,
  armorClass: number,
  spellSaveDc: number,
  rolledInitiative: number,
  passivePerception: number,
  hitpoints: number,
  dispatchPlayers: React.Dispatch<PIAction>
): PlayerInstance => {
  let playerBase = {
    characterName: characterName,
    playerName: playerName,
    /* base */
    level: 1,
    class: "",
    race: "",
    /* stats */
    armorClass: armorClass,
    maxHp: hitpoints,
    spellSaveDC: spellSaveDc,
    /*skills */
    passivePerception: passivePerception,
    passiveInvestigation: 0,
    passiveInsight: 0,
    /* */
  };

  let playerInstance = new PlayerInstance(playerBase);
  playerInstance.rolledInitiative = rolledInitiative;
  dispatchPlayers({
    type: PIActionType.AddOne,
    payload: [playerInstance],
  });

  return playerInstance;
};
