import { FunctionComponent, useEffect, useState } from "react";
import {
  IRandomTableEntry,
  ITableEntry,
  RandomizerType,
  readRandomCritFromJson,
  readRandomHitAreaHumanoidFromJson,
  readRandomHitAreaMonsterFromJson,
  readRandomInjuryFromJson,
  readRandomJunkItemFromJson,
  readRandomMissFromJson,
  readRandomMundaneItemFromJson,
  readRandomSpellcastingMisshapFromJson,
} from "../../api";
import { Row, Col, Button } from "react-bootstrap";
import { style_column_button, style_column_content, style_row } from "../../constants";
import { style_button } from "../../constants/styling";

export const SimpleRandomizer: FunctionComponent<{
  randomizerType: RandomizerType;
  randomizerName: string;
  buttonVariant: string;
}> = ({ randomizerType, randomizerName, buttonVariant }) => {
  const [entry, setEntry] = useState<IRandomTableEntry>({ value: "" });
  const [table, setTable] = useState<ITableEntry[]>([]);

  //when initializing, load random roll table
  useEffect(() => {
    switch (randomizerType) {
      case RandomizerType.Combat_CriticalMiss: {
        readRandomMissFromJson().then((result) => setTable(result));
        break;
      }
      case RandomizerType.Combat_CriticalHit: {
        readRandomCritFromJson().then((result) => setTable(result));
        break;
      }
      case RandomizerType.Combat_SpellcastingMishap: {
        readRandomSpellcastingMisshapFromJson().then((result) => setTable(result));
        break;
      }
      case RandomizerType.Combat_Hitarea_Humanoid: {
        readRandomHitAreaHumanoidFromJson().then((result) => setTable(result));
        break;
      }
      case RandomizerType.Combat_Hitarea_Monster: {
        readRandomHitAreaMonsterFromJson().then((result) => setTable(result));
        break;
      }
      case RandomizerType.Combat_Injury: {
        readRandomInjuryFromJson().then((result) => setTable(result));
        break;
      }
      case RandomizerType.Item_Junk: {
        readRandomJunkItemFromJson().then((result) => setTable(result));
        break;
      }
      case RandomizerType.Item_Mundane: {
        readRandomMundaneItemFromJson().then((result) => setTable(result));
        break;
      }
    }
  }, []);

  const getRandomRoll = () => {
    var randomEntry = table[Math.floor(Math.random() * table.length)];
    setEntry({ value: randomEntry.text });
  };

  return (
    <Row className={style_row}>
      <Col sm={2} className={style_column_button}>
        <Button className={style_button} variant={buttonVariant} onClick={() => getRandomRoll()}>
          {randomizerName}
        </Button>
      </Col>
      <Col sm={10} className={style_column_content}>
        <div>{entry.value}</div>
      </Col>
    </Row>
  );
};
