import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";
import { Col, Row, Image, Offcanvas, InputGroup, Form, Button } from "react-bootstrap";
import { DiceRollManyWithModifiers, Dices, DiceType, IDie, IRollResult } from "../../05_helpers";
import { style_dropdown } from "../../constants";

const colum_title_style = "text-center d-flex justify-content-center align-items-center py-2 bg-gray-2 text-muted";
const colum_content_style = "d-flex align-items-center justify-content-center ";
const row_style = "my-3";

export interface IModifier {
  name: string;
  value: number;
}

export const RollDiceDialog: FunctionComponent<{ show: boolean; setShow: Dispatch<SetStateAction<boolean>> }> = ({
  show,
  setShow,
}) => {
  const handleClose = () => setShow(false);

  return (
    <Offcanvas show={show} onHide={handleClose} scroll={true} placement="end" className="w-50">
      <Offcanvas.Header closeButton className="d-flex">
        <b className="flex-fill text-center fs-5">Roll</b>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <RollForm></RollForm>
        {/* Log */}
        <RenderTitleRow render={true} title="Log"></RenderTitleRow>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const RenderTitleRow: FunctionComponent<{ render: boolean; title: string; icon?: string }> = ({
  render,
  title,
  icon,
}) => {
  if (!render) {
    return null;
  }
  return (
    <Row>
      <Col sm={1} className={colum_title_style}>
        {icon ? <Image src={icon} width="35" /> : null}
      </Col>
      <Col sm={10} className={colum_title_style}>
        {title}
      </Col>
      <Col sm={1} className={colum_title_style}>
        {icon ? <Image src={icon} width="35" /> : null}
      </Col>
    </Row>
  );
};

const RollForm: FunctionComponent<{}> = () => {
  const [modifier, setModifier] = useState<number>(0);
  const [rolls, setRolls] = useState<number>(1);
  const [result, setResult] = useState<JSX.Element[]>([]);
  const [diceType, setDiceType] = useState<number>(Dices.get(DiceType.D20)!.value);

  const roll = () => {
    let results = DiceRollManyWithModifiers(diceType, rolls, [modifier]);
    let resultsDisplay = results.map((r) => <RollResultDisplay rollresult={r}></RollResultDisplay>);
    resultsDisplay.push(<RollResultDisplaySum rollresults={results}></RollResultDisplaySum>);
    setResult(resultsDisplay);
  };

  return (
    <>
      <Row className={row_style} style={{ fontSize: 14 }}>
        <Col sm={3}>
          <InputGroup>
            <InputGroup.Text style={{ minWidth: 60, fontSize: 13 }}>{"Dice"}</InputGroup.Text>
            <Form.Select
              className={style_dropdown}
              value={diceType}
              onChange={(e) => setDiceType(parseInt(e.currentTarget.value))}
            >
              {Array.from(Dices.values()).map((value: IDie) => (
                <option key={value.value} value={value.value}>
                  {value.name}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
        </Col>
        <Col sm={3} className={colum_content_style}>
          <InputGroup>
            <InputGroup.Text style={{ minWidth: 60, fontSize: 13 }}>{"#rolls "}</InputGroup.Text>
            <Form.Control type="number" value={rolls} onChange={(e) => setRolls(parseInt(e.currentTarget.value))} />
          </InputGroup>
        </Col>
        <Col sm={3} className={colum_content_style}>
          <InputGroup>
            <InputGroup.Text style={{ minWidth: 60, fontSize: 13 }}>{"modifier "}</InputGroup.Text>
            <Form.Control
              type="number"
              value={modifier}
              onChange={(e) => setModifier(parseInt(e.currentTarget.value))}
            />
          </InputGroup>
        </Col>
        <Col sm={1} className={colum_content_style}>
          <Button variant="secondary" onClick={roll}>
            roll
          </Button>
        </Col>
      </Row>
      <Row className={row_style}>{result.map((e) => e)}</Row>
    </>
  );
};

const RollResultDisplay: FunctionComponent<{ rollresult: IRollResult }> = ({ rollresult }) => {
  const renderModifiers = (modifier?: number) => {
    if (modifier == null || modifier === 0) {
      return null;
    } else {
      return (
        <>
          {" + [modifier] "}
          {rollresult.modifiers}
        </>
      );
    }
  };

  return (
    <Row className={"bg-blue-1 py-1"}>
      <Col sm={1}>
        <b>{rollresult.result}</b>
      </Col>
      <Col sm={11}>
        {" = "}
        {"[" + rollresult.diceName + "] "}
        {rollresult.roll}
        {renderModifiers(rollresult.modifiers)}
      </Col>
    </Row>
  );
};

const RollResultDisplaySum: FunctionComponent<{ rollresults: IRollResult[] }> = ({ rollresults }) => {
  const rolls = rollresults.map((r) => r.result);
  const rollResultSum = rolls.reduce((a, b) => a + b, 0);
  const min = Math.min.apply(Math, rolls);
  const max = Math.max.apply(Math, rolls);

  if (rolls.length <= 1) {
    return null;
  }

  return (
    <Row className={"bg-blue-1  py-1"}>
      <Col sm={2}>
        <b>{"sum = " + rollResultSum}</b>
      </Col>
      <Col sm={2}>
        <b>{"max = " + max}</b>
      </Col>
      <Col sm={2}>
        <b>{"min = " + min}</b>
      </Col>
    </Row>
  );
};
