import { FunctionComponent } from "react";
import { Container, Nav, Row, Tab } from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CombatTrackerPage } from "./DndTools";
import { AnswersPage } from "./quiz/Answers";
import { MusicQuizPage } from "./quiz/MusicQuiz";
import { MusicQuizSubmitPage } from "./quiz/MusicQuizSubmitPage";

export const MyRouting: FunctionComponent<{}> = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/combat" element={<CombatTrackerPage />} />
        <Route path="/musicquiz" element={<MusicQuizPage />} />
        <Route path="/musicquiz/answers" element={<AnswersPage />} />
        <Route path="/musicquiz/submit">
          <Route path=":docid" element={<MusicQuizSubmitPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export const Home: FunctionComponent<{}> = () => {
  const baseButtonStyling = "text-white";
  const toolsbuttonstyling = baseButtonStyling + " bg-orange-5";
  const navItemSpacing = "px-3 flex-fill text-center";

  return (
    <Container fluid>
      <Tab.Container id="custom-tab-example" defaultActiveKey="player_overview">
        <Row className="bg-gray-9">
          <Nav variant="pills" className="container-fluid d-flex flex-wrap py-3">
            <Nav.Item className={navItemSpacing}>
              <Nav.Link className={toolsbuttonstyling} href="/combat">
                D&amp;D Tools
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className={navItemSpacing}>
              <Nav.Link className={toolsbuttonstyling} href="/musicquiz">
                🤘 Goldfinger Music Quizz 🤘
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>
      </Tab.Container>
    </Container>
  );
};
