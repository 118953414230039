import { ITokenColor } from "../types";

export const style_column_content = "bg-light justify-content-start align-items-center d-flex py-2 px-3";
export const style_colum_title = style_column_content + " fw-bold";
export const style_column_button = "justify-content-end d-flex";
export const style_column_dropdown = "justify-content-center d-flex";
export const style_dropdown = "flex-fill";
export const style_button = "flex-fill";
export const style_row = "mb-4 container-fluid";
export const style_container = "px-4 pt-4";
export const style_vertical_center = "d-flex align-items-center justify-content-start";

//player info
export const style_player_container = "pt-4 d-flex flex-wrap";
export const style_player_div = "w-50 py-2 px-2 d-flex flex-column";
export const style_player_column_content = "w-25 bg-light py-2 px-3";
export const style_player_column_title = style_player_column_content + " fw-bold";
export const style_player_row = "d-flex flex-nowrap";

//player combat tracker
export const style_player_combattracker_container = style_player_container;

export const style_player_combattracker_div = "w-50 d-flex flex-column align-self-start mb-3 flex-fill";
export const style_player_combattracker_row_base = "mb-1 px-2 py-2";
export const style_player_combattracker_row = style_player_combattracker_row_base + " bg-light";

export const style_player_combattracker_col = "align-items-center d-flex";
export const style_player_overview_border = {
  outline: "1px solid #DEE2E6",
  outlineOffset: "-10px",
};

export const style_border = {
  border: "1px solid #DEE2E6",
};

export const getMonsterTokenBgColor = (color: ITokenColor): React.CSSProperties => {
  const styling = {
    backgroundColor: color.bgHex,
    color: "#333",
    border: color.name === "White" ? "1px solid #DEE2E6" : "none",
  };

  if (color.whiteText) {
    styling.color = "#ffffff";
  }

  return styling;
};
