import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";
import { Col, Row, Image, Offcanvas } from "react-bootstrap";
import { getMonsterTokenBgColor } from "../../constants";

import { IMonsterInstance, ISpellCasting } from "../../types";

const colum_title_style = "text-center d-flex justify-content-center align-items-center py-1 bg-gray-2 text-muted";
const colum_content_style = "py-1 text-center";
const span_distance = "me-3";
const justify_columns_center = "d-flex justify-content-center";

export const MonsterDetail: FunctionComponent<{
  monster: IMonsterInstance | undefined;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}> = ({ monster, show, setShow }) => {
  const [armorClass, setArmorClass] = useState<string>("");

  useEffect(() => {
    //set armorclass
    if (monster?.monsterBase) {
      setArmorClass(getFormattedAc(monster.monsterBase.ac));
    }
  }, [monster]);

  const handleClose = () => setShow(false);

  if (monster == null) {
    return null;
  }

  return (
    <Offcanvas show={show} onHide={handleClose} scroll={true} placement="end" className="w-50">
      <Offcanvas.Header
        closeButton
        className="d-flex"
        style={monster.color ? getMonsterTokenBgColor(monster.color) : {}}
      >
        <b className="flex-fill text-center fs-5">{monster.name}</b>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {/* Title */}

        {/* basic info */}
        <Row>
          <Col sm={2} className={colum_content_style}>
            <Image src="img/roundtracker/heart.svg" width="25" className="me-2" />
            {monster.maxHP + " / " + monster.currentHP}
          </Col>
          <Col sm={4} className={colum_content_style}>
            <Image src="img/roundtracker/shield.svg" width="25" className="me-2" />
            {armorClass}
          </Col>
          <Col sm={4} className={colum_content_style}>
            <Image src="img/roundtracker/trainer.svg" width="30" className="me-2" />
            {getFormattedSpeed(monster.monsterBase.speed)}
          </Col>
          <Col sm={2} className={colum_content_style}>
            Size: <b>{monster.monsterBase.size}</b>
          </Col>
        </Row>

        {/* ability scores*/}
        <RenderTitleRow
          render={true}
          title="Ability Score Modifiers"
          icon="img/roundtracker/reaction.svg"
        ></RenderTitleRow>
        <Row>
          {monster.monsterBase.abilityScores.map((abilityScore) => (
            <Col sm={2} className={colum_content_style}>
              {abilityScore.ability}
              {": "}
              <b>{abilityScore.modifier}</b>
            </Col>
          ))}
        </Row>

        {/* Skills */}
        <RenderTitleRow
          render={monster.monsterBase.skills !== null}
          title="Skill Modifiers"
          icon="img/roundtracker/global-network.svg"
        ></RenderTitleRow>
        <RenderModifiers modifiers={monster.monsterBase.skills} spacing="me-5"></RenderModifiers>

        {/* Saving Throws */}
        <RenderTitleRow
          render={monster.monsterBase.savingThrows !== null}
          title="Saving Throw Modifiers"
          icon="img/roundtracker/dice.svg"
        ></RenderTitleRow>
        <RenderModifiers modifiers={monster.monsterBase.savingThrows} spacing="me-5"></RenderModifiers>

        {/* Senses */}
        <RenderTitleRow
          render={monster.monsterBase.senses !== null}
          title="Senses"
          icon="img/roundtracker/vision.svg"
        ></RenderTitleRow>
        <RenderStringArray values={monster.monsterBase.senses}></RenderStringArray>

        {/* spellcasting */}
        {RenderSpellcasting(monster.monsterBase.spellcasting)?.map((sc) => sc.map((s) => s))}

        {/* Damage Immunities */}
        <RenderTitleRow
          render={monster.monsterBase.damageImmunities !== null}
          title="Damage Imunities"
        ></RenderTitleRow>
        <RenderDamageAffections dictionary={monster.monsterBase.damageImmunities}></RenderDamageAffections>

        {/* Damage Resistances */}
        <RenderTitleRow
          render={monster.monsterBase.damageResistances !== null}
          title="Damage Resistances"
        ></RenderTitleRow>
        <RenderDamageAffections dictionary={monster.monsterBase.damageResistances}></RenderDamageAffections>

        {/* Damage Vulnerabilities */}
        <RenderTitleRow
          render={monster.monsterBase.damageVulnerabilities !== null}
          title="Damage Vulnerabilities"
        ></RenderTitleRow>
        <RenderDamageAffections dictionary={monster.monsterBase.damageVulnerabilities}></RenderDamageAffections>

        {/* condition imunities */}
        <RenderTitleRow
          render={monster.monsterBase.conditionImmunities !== null}
          title="Condition Immunities"
        ></RenderTitleRow>
        <RenderStringArray values={monster.monsterBase.conditionImmunities}></RenderStringArray>

        {/* Misc Info */}
        <RenderTitleRow render={true} title="Misc Info"></RenderTitleRow>
        <Row>
          <Col sm={3} className={colum_content_style}>
            <Image src="img/eye.png" width="25" className="me-2" />
            Passive Perception: {monster.monsterBase.passivePerception}
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const getFormattedAc = (rawAc: { [key: string]: number }): string => {
  let acString = "";
  Object.entries(rawAc).forEach(([key, value]) => {
    acString = acString + value;
    acString = acString + " (";

    if (key === "Base AC") {
      acString = acString + key;
    } else {
      let parts = key.includes(", ") ? key.split(", ") : [key];
      parts.forEach((p) => {
        p = p.replace("{@item ", "");
        p = p.split("|")[0];
        acString = acString + p + ", ";
      });
    }

    acString = acString + ")  ";
    acString = acString.replace(", )", ")");
  });

  return acString;
};

const getFormattedSpeed = (rawSpeed: { [key: string]: number }): string => {
  let speedString = "| ";

  Object.entries(rawSpeed).forEach(([key, value]) => {
    speedString = speedString + key + " " + value / 5 + " | ";
  });

  return speedString;
};

const RenderDamageAffections: FunctionComponent<{ dictionary: { [key: string]: string } }> = ({ dictionary }) => {
  if (!dictionary) {
    return null;
  }

  return (
    <Row className={justify_columns_center}>
      {Object.entries(dictionary).map(([key, value]) => (
        <Col sm={2} className={colum_content_style} style={{ fontSize: 15 }}>
          <b>{key}</b>
          <br />
          {value}
        </Col>
      ))}
    </Row>
  );
};

const RenderStringArray: FunctionComponent<{ values: string[] }> = ({ values }) => {
  let spanSpacing = span_distance;
  if (values) {
    spanSpacing = values.length > 1 ? spanSpacing : "";
  }
  return values ? (
    <Row>
      <Col sm={12} className={colum_content_style}>
        {values.map((value) => (
          <span className={spanSpacing}>{value}</span>
        ))}
      </Col>
    </Row>
  ) : null;
};

const RenderTitleRow: FunctionComponent<{ render: boolean; title: string; icon?: string }> = ({
  render,
  title,
  icon,
}) => {
  if (!render) {
    return null;
  }
  return (
    <Row>
      <Col sm={1} className={colum_title_style}>
        {icon ? <Image src={icon} width="35" /> : null}
      </Col>
      <Col sm={10} className={colum_title_style}>
        {title}
      </Col>
      <Col sm={1} className={colum_title_style}>
        {icon ? <Image src={icon} width="35" /> : null}
      </Col>
    </Row>
  );
};

const RenderModifiers: FunctionComponent<{ modifiers: { [key: string]: number }; spacing?: string }> = ({
  modifiers,
  spacing,
}) => {
  const spanSpacingT = spacing ? spacing : span_distance;
  const spanSpacing = modifiers && Object.entries(modifiers).length > 1 ? spanSpacingT : "";

  if (!modifiers) {
    return null;
  }

  return (
    <Row>
      <Col sm={12} className={colum_content_style}>
        {Object.entries(modifiers).map(([key, value]) => (
          <span className={spanSpacing}>
            {key}: <b>{value}</b>
          </span>
        ))}
      </Col>
    </Row>
  );
};

const RenderSpellcasting = (spellcastings: ISpellCasting[]): JSX.Element[][] | null => {
  if (spellcastings == null) {
    return null;
  }

  return spellcastings.map((s) => [
    <RenderTitleRow render={true} title={s.type} icon="img/roundtracker/witch-hat.svg"></RenderTitleRow>,
    <SpellcastingBlock spellcasting={s}></SpellcastingBlock>,
  ]);
};

const SpellcastingBlock: FunctionComponent<{ spellcasting: ISpellCasting }> = ({ spellcasting }) => {
  return (
    <Row>
      <Col sm={4} className={colum_content_style}>
        <Image src="img/roundtracker/magic-wand.svg" width="25" className="mx-1" />
        Spell Save DC:
        <b className="ms-2">{spellcasting.spellSaveDc}</b>
      </Col>
      <Col sm={4} className={colum_content_style}>
        <Image src="img/roundtracker/meteor.svg" width="25" className="mx-1" />
        Spell Attack Modifier: <b className="ms-2">{spellcasting.spellAttackModifier}</b>
      </Col>
      <Col sm={4} className={colum_content_style}>
        Spellcasting Ability: <b className="ms-2">{spellcasting.spellcastingAbility}</b>
      </Col>
    </Row>
  );
};
