import { IMonsterBase, IMonsterInstance, ITokenColor, TokenColorArray } from "../";

export class MonsterInstance implements IMonsterInstance {
  id: string;
  index!: number;
  name!: string;
  color: ITokenColor;
  maxHP!: number;
  currentHP!: number;
  rolledInitiative: number;
  monsterBase: IMonsterBase;

  constructor(monsterBase: IMonsterBase, randMonsterHealth: boolean, id: number, colorIndex: number) {
    //get colorToken
    let selectedColor = TokenColorArray[colorIndex];

    this.id = id.toString() + Date.now().toString();
    this.color = selectedColor;
    this.rolledInitiative = 0;
    this.monsterBase = monsterBase;
    this.index = id;

    //set name
    this.buildName();
    //setHP
    this.regenerateMonsterHealth(randMonsterHealth);
    //rollInitiative
    this.rollNewInitiative();
  }

  buildName() {
    this.name = generateMonsterInstanceName(this.monsterBase.name, this.color.name, this.index);
  }

  regenerateMonsterHealth(randomHealth: boolean) {
    let hp = generateMonsterHP(this.monsterBase, randomHealth);
    this.maxHP = hp;
    this.currentHP = hp;
  }

  getAbilityScore(score: string): number {
    return getAbilityScoreByMonster(score, this.monsterBase);
  }

  rollNewInitiative() {
    let dex = getAbilityScoreByMonster("dex", this.monsterBase);
    this.rolledInitiative = rollInitiative(dex);
  }
}

const getAbilityScoreByMonster = (score: string, monsterBase: IMonsterBase): number => {
  return monsterBase.abilityScores.find((a) => a.ability === score)?.modifier || 0;
};

const rollInitiative = (dexterity: number) => {
  return Math.floor(Math.random() * (20 - 1 + 1) + 1) + dexterity;
};

const generateMonsterInstanceName = (name: string, color: string, index: number): string => {
  return "#" + index + " " + name;
};

const generateMonsterHP = (monsterBase: IMonsterBase, randMonsterHealth: boolean): number => {
  if (randMonsterHealth) {
    let randHP = monsterBase.hp.formula_modifier;
    for (let i = 0; i < monsterBase.hp.formula_multiplier; i++) {
      randHP += Math.floor(Math.random() * (monsterBase.hp.formula_dice_type - 1 + 1) + 1);
    }
    return randHP;
  } else {
    return monsterBase.hp.average;
  }
};
