import { FunctionComponent, useContext, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
  MIAction,
  MIActionType,
  MonsterInstanceContext,
  MonsterInstanceContract,
  MonsterInstanceState,
} from "../../04_Stores";
import { getMonsterTokenBgColor, style_dropdown, style_vertical_center } from "../../constants";
import { IMonsterBase, ITokenColor, MonsterInstance, TokenColorArray } from "../../types";
import Select from "react-select";

const BASEMONSTER_PLACEHOLDER = { label: "choose creature type", value: "UNDEFINED_MONSTER" };

export const RollInitiativeAddMonster: FunctionComponent<{
  randMonsterHealth: boolean;
  monsterBases: IMonsterBase[];
}> = ({ randMonsterHealth, monsterBases }) => {
  const [monsters, dispatchMonsters] = useContext<MonsterInstanceContract>(MonsterInstanceContext);
  const [baseMonsterName, setBaseMonsterName] = useState<{ label: string; value: string }>(BASEMONSTER_PLACEHOLDER);
  const [tokenColor, setTokenColor] = useState<ITokenColor>(TokenColorArray[8]);
  const monsternames: readonly { label: string; value: string }[] = [
    BASEMONSTER_PLACEHOLDER,
    ...monsterBases.map((value) => {
      return { label: value.name, value: value.name };
    }),
  ];

  return (
    <Row className="bg-gray-2 py-1 mb-2 me-1 d-flex">
      <Col sm={3} className={style_vertical_center + " text-secondary"}>
        add monster
      </Col>
      <Col sm={5}>
        <Select
          value={baseMonsterName}
          onChange={(e) => setBaseMonsterName(e === null ? BASEMONSTER_PLACEHOLDER : e)}
          options={monsternames}
        />
      </Col>
      <Col sm={3}>
        <InputGroup>
          <InputGroup.Text style={{ minWidth: 60, fontSize: 13 }}>{"token"}</InputGroup.Text>
          <Form.Select
            className={style_dropdown}
            value={tokenColor.index}
            onChange={(e) => setTokenColor(TokenColorArray[parseInt(e.currentTarget.value)])}
            style={getMonsterTokenBgColor(tokenColor)}
          >
            {TokenColorArray.map((value) => (
              <option key={value.index} value={value.index} style={getMonsterTokenBgColor(value)}>
                {value.name}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
      </Col>
      <Col sm={1}>
        <Button
          className="flex-fill"
          variant="outline-secondary"
          onClick={() => {
            addNewMonster(monsterBases, randMonsterHealth, baseMonsterName, tokenColor, monsters, dispatchMonsters);
          }}
        >
          add
        </Button>
      </Col>
    </Row>
  );
};

export const addNewMonster = (
  monsterBases: IMonsterBase[],
  randMonsterHealth: boolean,
  baseMonsterName: { label: string; value: string },
  tokenColor: ITokenColor,
  monsters: MonsterInstanceState,
  dispatchMonsters: React.Dispatch<MIAction>
): MonsterInstance => {
  let baseMonster = monsterBases.find((mb) => mb.name === baseMonsterName.value);
  if (baseMonster !== undefined) {
    let monster = new MonsterInstance(baseMonster, randMonsterHealth, monsters.data.length, tokenColor.index);
    dispatchMonsters({
      type: MIActionType.AddOne,
      payload: [monster],
    });

    return monster;
  }
  return {} as MonsterInstance;
};
