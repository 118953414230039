export const Rarities = [
  "none",
  "common",
  "uncommon",
  "rare",
  "very rare",
  "legendary",
  "artifact",
  "unknown",
  "other",
  "unknown (magic)",
];
export const MagicItemRarities = [
  "common",
  "uncommon",
  "rare",
  "very rare",
  "legendary",
  "artifact",
  "unknown (magic)",
];
export const GemstoneValues = [10, 50, 500, 1000];
export const ArtValues = [25, 250, 750, 2500, 7500];
