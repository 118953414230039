import { Container } from "react-bootstrap";
import { RandomizerType } from "../../api";
import { Row, Col, Button } from "react-bootstrap";
import { getRandomNpcApi, IRandomNpc } from "../../api";
import { FunctionComponent, useState } from "react";
import { ColorSwatch, GenderIcon } from "../../03_components";
import { style_column_content, style_player_combattracker_container, style_row } from "../../constants";

const title_colsize = 3;
const content_colsize = 3;
const row_style = "flex-fill";

export const NpcRandomizer: FunctionComponent<{}> = () => {
  const [npc, setNpc] = useState<IRandomNpc>({
    race: "",
    gender: "",
    firstName: "",
    skinColor: { colorName: "", hexValue: "" },
    hairColor: { colorName: "", hexValue: "" },
    eyeColor: { colorName: "", hexValue: "" },
  });

  const getRandomNpc = () => {
    getRandomNpcApi(RandomizerType.Npc).then((result) => setNpc(result));
  };

  return (
    <Container className={style_player_combattracker_container} fluid>
      <Container>
        <Row className={style_row}>
          <Col sm={12} className={"justify-content-center d-flex"}>
            <Button variant="sec-cyan" onClick={() => getRandomNpc()}>
              Randomize NPC
            </Button>
          </Col>
        </Row>
        <Row className={row_style}>
          <Col sm={title_colsize} className={style_column_content}>
            <div>First Name</div>
          </Col>
          <Col sm={content_colsize} className={style_column_content}>
            <div>{npc.firstName}</div>
          </Col>

          <Col sm={title_colsize} className={style_column_content}>
            <div>Skin Color</div>
          </Col>
          <Col sm={content_colsize} className={style_column_content}>
            <ColorSwatch hexcolorstate={npc.skinColor.hexValue} colornamestate={npc.skinColor.colorName}></ColorSwatch>
          </Col>
        </Row>
        <Row className={row_style}>
          <Col sm={title_colsize} className={style_column_content}>
            <div>Gender</div>
          </Col>
          <Col sm={content_colsize} className={style_column_content}>
            <div>
              {npc.gender} <GenderIcon genderstate={npc.gender}></GenderIcon>{" "}
            </div>
          </Col>

          <Col sm={title_colsize} className={style_column_content}>
            <div>Hair Color</div>
          </Col>
          <Col sm={content_colsize} className={style_column_content}>
            <ColorSwatch hexcolorstate={npc.hairColor.hexValue} colornamestate={npc.hairColor.colorName}></ColorSwatch>
          </Col>
        </Row>
        <Row className={row_style}>
          <Col sm={title_colsize} className={style_column_content}>
            <div>Race</div>
          </Col>
          <Col sm={content_colsize} className={style_column_content}>
            <div>{npc.race}</div>
          </Col>

          <Col sm={title_colsize} className={style_column_content}>
            <div>Eye Color</div>
          </Col>
          <Col sm={content_colsize} className={style_column_content}>
            <ColorSwatch hexcolorstate={npc.eyeColor.hexValue} colornamestate={npc.eyeColor.colorName}></ColorSwatch>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
