import { FunctionComponent, useEffect, useState } from "react";

export const GenderIcon: FunctionComponent<{ genderstate: string }> = ({ genderstate }) => {
  const [genderflag, setGenderflag] = useState<string>("");

  useEffect(() => {
    if (genderstate === "male") {
      setGenderflag("♂️");
    } else if (genderstate === "female") {
      setGenderflag("♀️");
    } else {
      setGenderflag("");
    }
  }, [genderstate]);

  return <span>{genderflag}</span>;
};
