import { FunctionComponent, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useStore } from "react-redux";
import { style_player_combattracker_col, style_player_combattracker_row_base } from "../../constants";

export const MiscConditionElement: FunctionComponent<{ placeholderText: string }> = ({ placeholderText }) => {
  const [conditionName, setConditionName] = useState<string>("");
  const [turnsState, setTurnsState] = useState<number>(2);
  const [render, setRender] = useState<boolean>(true);

  /* subscribe to turn event*/
  const store = useStore();
  const turnIncreaseEvent = () => {
    setTurnsState((t) => (t > 0 ? t - 1 : t));
  };
  useEffect(() => {
    store.subscribe(turnIncreaseEvent);
  }, []);

  useEffect(() => {
    if (turnsState < 1) {
      setRender(false);
    }
  }, [turnsState]);

  if (!render) {
    return null;
  }

  return (
    <Row className={style_player_combattracker_row_base}>
      <Col sm={1} className={style_player_combattracker_col}>
        Turns left
      </Col>
      <Col sm={1} className={style_player_combattracker_col}>
        <Form.Control
          value={turnsState}
          type="number"
          placeholder=""
          onChange={(e) => setTurnsState(parseInt(e.currentTarget.value))}
        />
      </Col>

      <Col sm={10} className={style_player_combattracker_col}>
        <Form.Control
          value={conditionName}
          type="text"
          placeholder={placeholderText}
          onChange={(e) => setConditionName(e.currentTarget.value)}
        />
      </Col>
    </Row>
  );
};
