import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCqD2RM2iZpiYhGGNunUGVZNJ-A1HpcN18",
  authDomain: "kitsuneonclouds-e87dd.firebaseapp.com",
  projectId: "kitsuneonclouds-e87dd",
  storageBucket: "kitsuneonclouds-e87dd.appspot.com",
  messagingSenderId: "418609977012",
  appId: "1:418609977012:web:40b289085d71eb308ee9e1",
  measurementId: "G-YH9TBQKGM1",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
